import React, { useEffect, useState, useCallback, useRef } from 'react'
import { Sidebar } from '../../components/navigation/sidebar'
import Button from '../../components/button'
import Select from 'react-select'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import DataCard from '../../components/DataCard'
import app_api from '../../config/api'
import { CiFilter } from 'react-icons/ci'
import moment from 'moment'
import OTPInput from './OTPInput'

export default function Dashboard() {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [startDate, setStartDate] = useState()
  const [endDate, setEndDate] = useState()
  const [locationFilter, setLocationFilter] = useState([])
  const [location, setLocation] = useState([])
  const [isFilterSidebarOpen, setIsFilterSidebarOpen] = useState(false)
  const [dashboardData, setDashboardData] = useState(null)
  const sidebarRef = useRef(null)

  //   const oneMonthDateFun = () => {
  //     const start = moment().startOf('month').toDate()
  //     const end = moment().toDate()
  //     setStartDate(start)
  //     setEndDate(end)
  //   }

  const getLocation = useCallback(() => {
    const email = JSON.parse(localStorage.getItem('moldKey'))?.email
    const url = `location/all?&email=${email}`

    app_api
      .get(url)
      .then((res) => setLocation(res.data?.data || []))
      .catch((err) =>
        setError(err?.response?.data?.message || 'Error fetching locations')
      )
  }, [])

  const getDashboardData = useCallback(() => {
    const moldKey = JSON.parse(localStorage.getItem('moldKey'))
    const { role, id: user_id, locationId: location_id } = moldKey || {}
    const payload = {}

    if (role === 'manager') {
      payload.user_id = user_id
      payload.location_id = location_id
    }
    payload.role = role
    if (startDate && endDate) {
      payload.start_date = moment(startDate).format('DD-MM-YYYY')
      payload.end_date = moment(endDate).format('DD-MM-YYYY')
    }
    if (locationFilter.length) {
      payload.selected_location_id = locationFilter.map((loc) => loc.value)
    }

    app_api
      .post('dashboard/getDashboardData', payload)
      .then((res) => setDashboardData(res.data))
      .catch((err) =>
        setError(
          err?.response?.data?.message || 'Error fetching dashboard data'
        )
      )
  }, [startDate, endDate, locationFilter])

  const handleClearFilters = () => {
    // oneMonthDateFun()
    setStartDate()
    setEndDate()
    setLocationFilter([])
    setIsFilterSidebarOpen(false)
  }

  const handleClickOutside = (event) => {
    if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
      setIsFilterSidebarOpen(false)
    }
  }

  useEffect(() => {
    // if (startDate && endDate) {
    getDashboardData()
    // }
    getLocation()
  }, [getDashboardData, getLocation])

  console.log(startDate, endDate)

  useEffect(() => {
    if (isFilterSidebarOpen) {
      document.addEventListener('mousedown', handleClickOutside)
    } else {
      document.removeEventListener('mousedown', handleClickOutside)
    }
    return () => document.removeEventListener('mousedown', handleClickOutside)
  }, [isFilterSidebarOpen])

  console.log(dashboardData)

  return (
    <>
      <Sidebar>
        <div className="px-4 pt-2 sm:px-6 lg:px-8 ContainerUI sticky min-h-screen">
          <div className="flex justify-between items-center">
            <h1 className="text-3xl mt-3 font-semibold text-gray-900">
              Dashboard
            </h1>
            {!isFilterSidebarOpen && (
              <div
                onClick={() => setIsFilterSidebarOpen((prev) => !prev)}
                className="rounded-full py-2 px-2 w-10 cursor-pointer shadow-sm bg-[#08285b] text-white"
              >
                <CiFilter className="w-6 h-6" />
              </div>
            )}
          </div>
          <div className="mt-4 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-3">
            {dashboardData
              ?.filter((term) => term.isPrevTerm || term.isActiveTerm)
              .sort((a, b) => new Date(a.startDate) - new Date(b.startDate))
              .reverse()
              .map((item, index) => {
                return (
                  <>
                    <h4 className="text-2xl mt-3 font-bold text-gray-900 col-span-full">
                      {item.name} | {moment(item.startDate).format("DD-MM-YYYY") + " To " + moment(item.endDate).format("DD-MM-YYYY")}
                    </h4>

                    <h4 className="text-xl mt-3 font-bold text-gray-900 col-span-full">
                      Leads
                    </h4>

                    {item.leadsCount.map((data, number) => {
                      return (
                        <>
                          <DataCard
                            key={number}
                            name={data.status}
                            count={data.status_count}
                            url={'/leads'}
                          />
                        </>
                      )
                    })}
                    <h4 className="text-xl mt-3 font-bold text-gray-900 col-span-full">
                      Payments
                    </h4>
                    <DataCard
                      name="Total Amount Payable"
                      count={
                        '$ ' +
                        (item.paymentData.total_amount_payable -
                          item.paymentData.total_discount)
                      }
                    />
                    <DataCard
                      name="Total Paid Amount"
                      count={'$ ' + item.paymentData.total_paid_amount}
                    />
                    <DataCard
                      name="Total Pending Amount"
                      count={
                        '$ ' +
                        (item.paymentData.total_amount_payable -
                          item.paymentData.total_discount -
                          item.paymentData.total_paid_amount)
                      }
                    />
                  </>
                )
              })}
            {/* <h4 className="text-xl mt-3 font-bold text-gray-900 col-span-full">
              PAYMENTS
            </h4>
            {dashboardData?.map((item, index) => {
              return (
                <>
                  <h4 className="text-xl mt-3 font-bold text-gray-900 col-span-full">
                    {item.name} / LEADS
                  </h4>

                  {item.leadsCount.map((data, number) => {
                    return (
                      <DataCard
                        key={number}
                        name={data.status}
                        count={data.status_count}
                        url={'/leads'}
                      />
                    )
                  })}
                </>
              )
            })}
            {dashboardData?.paymentData && (
              <>
                <DataCard
                  name="Total Amount Payable"
                  count={
                    '$ ' +
                    (dashboardData.paymentData.total_amount_payable -
                      dashboardData.paymentData.total_discount)
                  }
                />                
                <DataCard
                  name="Total Paid Amount"
                  count={'$ ' + dashboardData.paymentData.total_paid_amount}
                />
                <DataCard
                  name="Total Pending Amount"
                  count={
                    '$ ' +
                    (dashboardData.paymentData.total_amount_payable -
                      dashboardData.paymentData.total_discount -
                      dashboardData.paymentData.total_paid_amount)
                  }
                />
              </>
            )} */}
          </div>
        </div>
        {isFilterSidebarOpen && (
          <div className="h-full" ref={sidebarRef}>
            <div className="absolute bg-white border rounded-lg shadow-lg w-full md:w-1/2 lg:w-1/4 top-[10%] right-[5%] z-50 p-2">
              <div className="flex justify-between mb-2">
                <span className="text-sm text-gray-700">Filters</span>
                <span
                  className="text-sm text-indigo-700 cursor-pointer"
                  onClick={handleClearFilters}
                >
                  Reset All
                </span>
              </div>
              <hr />
              <div className="w-full flex flex-col p-2">
                <div className="flex justify-between mb-2">
                  <span className="text-sm text-gray-700">Date Range</span>
                  <span
                    className="text-sm text-indigo-700 cursor-pointer"
                    onClick={() => {
                      setStartDate()
                      setEndDate()
                    }}
                  >
                    Reset
                  </span>
                </div>
                <DatePicker
                  selected={startDate}
                  onChange={(dates) => {
                    const [start, end] = dates
                    setStartDate(start)
                    setEndDate(end)
                  }}
                  startDate={startDate}
                  endDate={endDate}
                  selectsRange
                  isClearable
                  maxDate={new Date()}
                  dateFormat="MMM dd, yyyy"
                  placeholderText="Select Date Range"
                  className="text-center block py-2 px-2 border rounded-md w-full"
                />
              </div>
              <hr />
              <div className="w-full p-2">
                <div className="flex justify-between mb-2">
                  <span className="text-sm text-gray-700">Location</span>
                  <span
                    className="text-sm text-indigo-700 cursor-pointer"
                    onClick={() => setLocationFilter([])}
                  >
                    Reset
                  </span>
                </div>
                <Select
                  options={location.map((l) => ({
                    label: l.name,
                    value: l.id,
                  }))}
                  isMulti
                  isClearable
                  value={locationFilter}
                  onChange={(option) => setLocationFilter(option || [])}
                  placeholder="Select Location"
                  className="w-full"
                />
              </div>
              <hr />
              <div className="flex justify-between gap-2 p-2">
                <Button
                  onClick={handleClearFilters}
                  className="bg-red-500 hover:bg-red-700"
                >
                  Reset All
                </Button>
                {/* <Button
                  onClick={() => {
                    getDashboardData()
                    setIsFilterSidebarOpen(false)
                  }}
                >
                  Apply Now
                </Button> */}
              </div>
            </div>
          </div>
        )}
      </Sidebar>
    </>
  )
}
