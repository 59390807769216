import React from 'react'
import { useNavigate } from 'react-router-dom'

export default function DataCard(props) {
    const navigate = useNavigate()
  return (
    <div class="relative flex flex-col justify-center overflow-hidden py-6" onClick={(e)=>{
        e.preventDefault();
        if (props.url) {
            navigate(props.url)
        }
    }}>
      <div class="group relative cursor-pointer overflow-hidden bg-white px-6 pt-6 pb-4 border border-[gray] transition-all duration-300 hover:-translate-y-1 sm:rounded-lg sm:px-5">
        <span class="absolute top-6 z-0 h-[34%] w-[86%] p-2 rounded-full bg-[#08285b] transition-all duration-300 group-hover:scale-[10]"></span>
        <div class="relative z-10 mx-auto max-w-xl">
          <span class="grid h-full w-full rounded-full bg-[#08285b] transition-all duration-300 group-hover:bg-white text-[white] group-hover:text-[black]/90 text-xl font-bold p-2 px-4 whitespace-nowrap">
          {props.count || 0}
          </span>
          <div class="space-y-6 pt-2 mt-2 text-base font-semibold leading-7 text-[#08285b] transition-all duration-300 group-hover:text-white whitespace-nowrap">
            <p>
            {props.name || 0}
            </p>
          </div>          
        </div>
      </div>
    </div>
  )
}
