import React from 'react'

export default function TermTablePagination(props) {
  const { count, page, totalPages, limit, setLimit, setPage, goPrev, goNext } = props
  return (
    <nav
      className="flex flex-col md:flex-row md:items-center md:justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6"
      aria-label="Pagination"
    >
      <div>
        <p className="text-sm text-gray-700">
          Showing{' '}
          <select
            onChange={(e) => {
              setLimit(e.target.value)
              setPage(0)
            }}
            value={limit}
          >
            <option value={count}>{count}</option>
            <option value="10">10</option>
            <option value="15">15</option>
            <option value="20">20</option>
            <option value="50">50</option>
            <option value="100">100</option>
            <option value="200">200</option>
          </select>{' '}
          of <span className="font-medium">{count}</span> results
        </p>
      </div>
      <div className="flex items-center md:justify-end">
        <span
          onClick={goPrev}
          className="relative mr-3 items-center rounded-md px-3 py-2 text-sm font-semibold text-gray-900 border hover:bg-indigo-800 hover:text-white duration-500 cursor-pointer"
        >
          Previous
        </span>
        <span className="text-sm">
          Page{' '}
          <input
            className="min-w-0 flex-1 rounded-md border border-gray-300 px-3 py-2 focus:border-indigo-700 focus:outline-none focus:ring-indigo-100 sm:text-sm"
            type="number"
            value={page + 1}
            onChange={(e) => setPage(e.target.value - 1)}
            max={totalPages}
            min="1"
          />
          <span className="ml-2"></span>/ {Math.ceil(totalPages)}
        </span>
        <span
          onClick={goNext}
          className="relative ml-3 items-center rounded-md px-3 py-2 text-sm font-semibold text-gray-900 border hover:bg-indigo-800 hover:text-white duration-500 cursor-pointer"
        >
          Next
        </span>
      </div>
    </nav>
  )
}
