import { useState, useEffect, useCallback } from 'react'
import Modal from '../components/modals'
import Button from '../components/button'
import app_api from '../config/api'
import Table from '../components/tables/table'
import { crm_invoice_columns } from '../components/tables/tableheader'
import { Sidebar } from '../components/navigation/sidebar'
import { Formik } from 'formik'
import Select from 'react-select'
import 'react-phone-input-2/lib/style.css'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import BigSlideover from '../components/big-slideover'
import HalfGeneralSlideover from '../components/half-general-slideover'
import ConfirmDialog from '../components/dialog/confirmation_dialog'
import moment from 'moment'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { DocumentArrowDownIcon, PlusIcon, XCircleIcon } from '@heroicons/react/20/solid'
import CurrencyInput from 'react-currency-input-field'
import logo from '../assets/icons/logo.png'
import { markAsPaidSchema, familySchema, familyStudentSchema, creditNoteSchema,refundSchema,locationSchema } from '../schema'
import {  ArrowDownIcon, InformationCircleIcon, CheckCircleIcon , PrinterIcon, FunnelIcon, MagnifyingGlassIcon, PencilIcon} from '@heroicons/react/24/outline'
import { Switch } from '@headlessui/react'
import Input from '../components/input'
import { classNames } from '../helpers/classname'
import ConfirmationTextDialog from '../components/dialog/confirmation_text_dialog'
import ColumnSlideOver from '../components/column_slide_over'
import { MdOutlineFileDownload } from 'react-icons/md'
import { CiFilter } from 'react-icons/ci'

const initialModalState = {
  type: 'add',
  state: false,
  index: null,
  edit_id: '',
  data: {
    customerId: null,
    date: new Date(),
    dueDate: '',
    serviceId: '',
    termId: '',
    subjectId: '',
    discount: '',
    servicePayload: [],
    typeOfService:'',
  },
}

const paymentType = [
  {
    id: 'Cash',
    name: 'CASH',
  },
  {
    id: 'Bank Transfer',
    name: 'BANK TRANSFER',
  },
]

const initialMarkAsPaidModalState = {
  type: 'add',
  paidState: false,
  index: null,
  edit_id: '',
  data: {
    paymentMethod: null,
    date: new Date(),
    amount: '',
    description: null,
  },
}

const initialCreditState = {
  type: 'add',
  paidState: false,
  index: null,
  edit_id: '',
  data: {
    date: new Date(),
    amount: '',
    description: '',
  },
}

const initialFamilyModalState = {
  type: 'add',
  state: false,
  index: null,
  edit_id: '',
  data: {
    title: '',
    firstName: '',
    lastName: '',
    email: '',
    additionalEmail: '',
    mobileNo: '',
    workNo: '',
    homeNo: '',
    address1: '',
    address2: '',
    parentState: '',
    city: '',
    country: 'Australia',
    postCode: '',
    center: 0,
    additionalInfo: '',
  },
}

const initialStudentModalState = {
  type: '',
  modalState: false,
  index: null,
  edit_id: '',
  data: {
    firstName: '',
    lastName: '',
    email: '',
    studentPhone: '',
    address1: '',
    address2: '',
    parentFirstName: '',
    parentLastName: '',
    parentEmail: '',
    parentPhone: '',
    studentState: '',
    city: '',
    postCode: '',
    selectFamily: '',
    grade: '',
  },
}

const titles = [
  {
    id: 'Mr',
    name: 'Mr',
  },
  {
    id: 'Mrs',
    name: 'Mrs',
  },
  {
    id: 'Miss',
    name: 'Miss',
  },
]

const groupSizeOptions = [
  {
    id: 'individual',
    name: '1 on 1 Class',
  },
  {
    id: 'group',
    name: 'Group Class',
  },
]

const invoiceStatusType = [
  {
    id: 'VOIDED',
    name: 'VOIDED',
  },
  {
    id: 'AWAITING PAYMENT',
    name: 'AWAITING PAYMENT',
  },
  {
    id: 'PAID',
    name: 'PAID',
  },
]

const reasons = [
  {
    id: 'Course cancellation',
    name: 'Course cancellation',
  },
  {
    id: 'Adjustment for Missed Classes',
    name: 'Adjustment for Missed Classes',
  },
  {
    id: 'Discontinue classes/tuition.',
    name: 'Discontinue classes/tuition.',
  },
]

const initialRefundState = {
  type: '',
  refund_state: false,
  index: null,
  refund_edit_id: '',
  data: {
    paymentMethod: '',
    date: '',
    BSB: '',
    accountNumber: '',
  },
}

const initialAllocateModalState = {
  type: '',
  state: false,
  index: null,
  edit_id: '',
  data: {
    creditId: '',
    familyId: '',
    invoicId: '',
    description: null,
    amount: '',
  },
}

const initialLocationModalState = {
  type: '',
  state: false,
  index: null,
  edit_id: '',
  data: {
    name: '',
    address: '',
    pointOfContact: '',
    is_active: true,
    accountName: '',
    BSB: '',
    accountNumber: '',
    abn: '',
    gst: '',
    phoneNumber: '',
    emailAddress: '',
    xeroAPIKey: '',
    state: '',
    city: '',
    postCode: '',
    state: '',
    entityName: '',
    entityTypeName: '',
    status: '',
    entityTypeCode: '',
    googleurl: '',
    facebook: '',
    pointOfContact: '',
    brandingThemeID: '',
    sendGridAccount: null
  },
}

const typeOfServiceOptions = [
  {
    id: 'Regular',
    name: 'Regular',
  },
  {
    id: 'Workshop',
    name: 'Workshop',
  },
]

const InvoiceMaster = () => {
  const [modal, setModal] = useState(initialModalState)
  const [markAsPaidModal, setMarkAsPaidModal] = useState(
    initialMarkAsPaidModalState
  )
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const [confirmationDialog, setConfirmationDialog] = useState(false)
  const [crmInvoice, setCRMInvoice] = useState([])
  const [updateID, setUpdateID] = useState(null)
  const [parent, setParent] = useState([])
  const [paymentHistory, setPaymentHistory] = useState()
  const [service, setService] = useState([])
  const [invoiceDueDate, setInvoiceDueDate] = useState(new Date())
  const [termData, setTermData] = useState([])
  const [payload, setPayload] = useState([])
  const [serviceValue, setTempServiceValue] = useState(null)
  const [termsValue, setTermsValue] = useState(null)
  const [totalAmount, setTotalAmount] = useState(0)
  const [amountPayable, setAmountPayable] = useState(0)
  const [discount, setDiscount] = useState(0)
  const [discountDescription, setDiscountDescription] = useState([])
  const [descriptionValue, setDescriptionValue] = useState(null)
  const [serviceId, setServiceId] = useState([])
  const [locationAddress, setLocationAddress] = useState([])
  const [location, setLocation] = useState([])
  const [student, setStudent] = useState([])
  const [parentValue, setParentValue] = useState()
  const [studentValue, setStudentValue] = useState()
  const [subjectOptions, setSubjectOptions] = useState([])
  const [groupSizeOptionsValue, setgroupSizeOptionsValue] = useState(null)
  const [classOption, setClassOption] = useState([])
  const [classSelect, setClassSelect] = useState('')
  const [subject, setSubject] = useState([])
  const [subjectValue, setSubjectValue] = useState([])
  const [invoiceFilter, setInvoiceFilter] = useState(null)
  const [discountError, setDiscountError] = useState(false)
  const [locationFilter, setLocationFilter] = useState('')
  const [TabData, setTabData] = useState([])
  const [wholeFormDisable, setWholeFormDisable] = useState(false)
  const [partPayment, setPartPayment] = useState(0)
  const [creditPayment, setCreditPayment] = useState(0)
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [invoiceDate, setInvoiceDate] = useState(new Date());
  const [markAsPaidPayable, setMarkAsPaidPayable] = useState(0)
  const [invoiceData, setInvoiceData] = useState()
  // const [charges, setCharges] = useState(0)
  const [groupErrors, setGroupErrors] = useState(false)
  const [subjectError, setSubjectError] = useState(false)
  const [gradeError, setGradeError] = useState(false)
  const [discription, setDiscription] = useState('')
  // Pagination Start
  const [page, setPage] = useState(0)
  const [limit, setLimit] = useState(15)
  const [totalPages, setTotalPages] = useState(1)
  const [count, setCount] = useState(0)
  const [searchFilter, setSearchFilter] = useState('')
  const [creditModal, setCreditModal] = useState(initialCreditState)

  const [parentEnabled, setParentEnabled] = useState('Prospective')
  const [postCodeError, setPostCodeError] = useState('')
  const [studentState, setStudentState] = useState([])
  const [studentCity, setStudentCity] = useState([])
  const [defaultState, setDefaultState] = useState([])
  const [familyDetails, setFamilyDetails] = useState({})
  const [familyModal, setFamilyModal] = useState(initialFamilyModalState)
  const [studentModal, setStudentModal] = useState(initialStudentModalState)
  const [studentEnabled, setStudentEnabled] = useState('Prospective')
  const [grade, setGrade] = useState()
  const [customer, setCustomer] = useState([])
  const [customerData, setCustomerData] = useState('')
  const [renderEditModal, setRenderEditModal] = useState(false)
  const [editStudent, setEditStudent] = useState([])
  const [disable, setDisable] = useState(true)
  const [studentName, setStudentName] = useState('')
  const [studentData, setStudentData] = useState([])
  const [refundModal, setRefundModal] = useState(initialRefundState)
  const [creditAmount, setCreditAmount] = useState(0)
  const [calculateAmount, setCalculateAmount] = useState(0)
  const [balanceAmount, setBalanceAmount] = useState(0)
  const [creditCreatedAt, setCreditCreatedAt] = useState(0)
  const [select, setSelect] = useState([])
  const [confirmationRefundDialog, setConfirmationRefundDialog] = useState(false)
  const [allocateModal, setAllocateModal] = useState(initialAllocateModalState)
  const [locationModal, setLocationModal] = useState(initialLocationModalState)
  const [LocationManager, setLocationManager] = useState([])
  const [abnStatus, setAbnStatus] = useState()
  const [entityName, setEntityName] = useState()
  const [gstValue, setGstValue] = useState()
  const [abnError, setAbnError] = useState('')
  const [classPayload, setClassPayload] = useState([])

  const [columns, setColumns] = useState([])
  const [columnFilter, setColumnsFilter] = useState([])
  const [openSlider, setOpenSlider] = useState(false)
  const [classDate, setClassDate] = useState()
  let columnName = 'invoice'
  let user_id = JSON.parse(localStorage.getItem('moldKey')).id
  let title = 'Category Columns'
  let description = 'Customize columns according to your preferences'

  let data = []
  const [isFilterSidebarOpen, setIsFilterSidebarOpen] = useState(false)
  const [filterData, setFilterData] = useState({
    startDate: null,
    endDate: null,
    location: '',
    status: '',
    loading: false,
  })
  const [downloadButtonLoading, setDownloadButtonLoading] = useState(false)

  const handleFilters = () => {
    setFilterData({
      ...filterData,
      loading: true,
    })
    setIsFilterSidebarOpen(!isFilterSidebarOpen)
    setStartDate(filterData.startDate)
    setEndDate(filterData.endDate)
    setLocationFilter(filterData.location)
    setInvoiceFilter(filterData.status)
  }

  const handleClearFilters = () => {
    setStartDate()
    setEndDate()
    setLocationFilter([])
    setInvoiceFilter(null)
    setIsFilterSidebarOpen(!isFilterSidebarOpen)
  }

  const goPrev = () => {
    if (page > 0) setPage((prev) => --prev)
  }

  const goNext = () => {
    if (page < totalPages - 1) setPage((prev) => ++prev)
  }

  useEffect(() => {
    let locationData = location.filter(l => l.id == locationAddress)
  }, [endDate, locationAddress])

  const handleClick = (num) => {
    let payload = num
    app_api
      .patch(`/user-setting/${user_id}/${columnName}`, payload)
      .then((res) => {
        setOpenSlider(false)
        getColumns()
      })
      .catch((err) => {

        setError(err?.response?.data?.message || 'error getting data')
      })
  }

  const getColumns = () => {
    app_api
      .get(`/user-setting/${user_id}/${columnName}`)
      .then((res) => {
        let data = res.data
        setLoading(false)
        setError(null)

        setColumnsFilter(data)
        let dynamic_columns = data.map((key) => {
          if (key.active == true) {
            return {
              Header: key.header == 'Centre' ? 'Location' : key.header,
              accessor: key.table
                ? key.table + '.' + key.column
                : key.column,
            }
          }
        })
        let columns = [...dynamic_columns]
        columns = columns.filter(function (element) {
          return element !== undefined
        })

        setColumns(columns)
      })
      .catch((err) => {

        setError(err?.response?.data?.message || 'error getting data')
      })
  }

  const renderTable = useCallback(() => {
    return (
      <>
        <Table
          progPage={page}
          totalCount={count}
          columns={crm_invoice_columns({
            onEditOpen,
            onDeleteOpen,
            onMarkAsPaid,
            markPaidTeachwork,
            onFamilyEditOpen,
            onResendMail,
            onEditLocationOpen,
            columns
          })}
          data={TabData}
          onEditClose={cleanModalData}
          setSearchFilter={setSearchFilter}
        />
      </>
    )
  }, [TabData,columns])


  const onResendMail = (id) => {
    app_api.get(`crm-invoice/resendInvoice/${id}`)
    .then((res)=>{
        toast.success('Successfully resent invoice')
      })
      .catch((err) => {
        toast.error('Failed to resend invoice')
      })
  }

  const getCRMInvoiceMasterData = () => {
    let email = JSON.parse(localStorage.getItem('moldKey')).email
    let locationValues = locationFilter && locationFilter.length > 0
      ? locationFilter.map(value => value.id)
      : null;
    let url = `crm-invoice/all?page=${page}&size=${limit}&searchFilter=${searchFilter}&email=${email}`
    if (locationValues !== null && locationValues.length > 0) {
      url += `&location=${locationValues.join(',')}`;
    }
    // if(locationFilter?.value) url = url+`&location=${locationFilter?.value}`
    if (invoiceFilter?.value) url = url + `&status=${invoiceFilter?.value}`
    if (startDate != null && endDate != null) url = url + `&start=${startDate}&end=${endDate}`
    app_api
      .get(url)
      .then(res=>res.data)
      .then((res) => {
        setError(null);
        if(res.count <= limit) setPage(0)
        setFilterData({
          ...filterData,
          loading: false,
        })
        setCRMInvoice(res.data);
        setTabData(res.data);
        if(isNaN(res.count / limit)){setTotalPages(1)}else{setTotalPages(res.count / limit)}
        setCount(res.count)
        if (res.count < 15) { setLimit(res.count) } else { setLimit(15) }
      })
      .catch((err) => {
        
        setError(err?.response?.data?.message || 'error getting data')
      })
  }

  useEffect(() => {
    getCRMInvoiceMasterData()
  }, [page, limit, searchFilter, locationFilter, invoiceFilter, endDate, startDate])


  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      let email = JSON.parse(localStorage.getItem('moldKey')).email
      let url = `/family-master/select?&email=${email}&size=${limit}&searchFilter=${customerData}`
      app_api
        .get(url)
        .then((res) => {

          setError(null)
          setCustomer(res.data)
        })
        .catch((err) => {

          setError(err?.response?.data?.message || 'error getting data')
        })
    }, 1000)

    return () => clearTimeout(delayDebounceFn)
  }, [customerData])


  const onCancelInvoice = (d) => {
    const {data} = modal
    let payload = {
      updatedBy: JSON.parse(localStorage.getItem('moldKey')).email,
      data:d
    }
    app_api
      .patch(`/crm-invoice/cancel-invoice/${updateID}`, payload)
      .then((res) => {
        toast.success('Invoice Voided Successfully')
        getCRMInvoiceMasterData()
        cleanModalData()
      })
      .catch((err) => {

        setError(err?.response?.data?.message || 'error getting data')
        toast.error('Something went wrong')
      })
  }

  useEffect(() => {}, [partPayment])

  useEffect(() => {}, [creditPayment])

  const cleanModalData = () => {
    setInvoiceDate(new Date())
    const date = new Date()
    date.setDate(date.getDate() + 7)
    setInvoiceDueDate(date)
    setModal(initialModalState)
    setSubjectValue([])
    setPartPayment(0)
    setCreditPayment(0)
    setPaymentHistory(null)
    setParentValue(null)
    setLocationAddress(null)
    setgroupSizeOptionsValue(null)
    setStudentValue(null)
    setPayload([])
    setDiscount(0)
    setClassOption([])
    setClassSelect([])
    setGroupErrors(false)
    setWholeFormDisable(false)
    setClassPayload([])
  }

  const cleanMarkAsPaidModalData = () => {
    setMarkAsPaidModal(initialMarkAsPaidModalState)
  }

  const cleanCreditModalData = () => {
    setCreditModal(initialCreditState)
  }

  useEffect(() => {}, [payload, markAsPaidModal, markAsPaidPayable, discription])


  const filterVCEEditOpen = (value,data) => {
    if (value) {
      let Familydata = familyModal.data
      if (Familydata[0]?.studentMaster[0]?.gradeMaster?.vce == true) {
        let subjectOptions = data.filter((l) => l.vce == true)
        setSubjectOptions(subjectOptions)
      } else {
        let subjectOptions = data.filter(l => l.vce == false)
        setSubjectOptions(subjectOptions)
      }
    } else {
      let subjectOptions = data
      setSubjectOptions(subjectOptions)
    }
    setSubjectValue([])
    setgroupSizeOptionsValue(null)
  }

  const onEditInvoiceOpen = async (id, index) => {
    let data
    setRenderEditModal(true)
    app_api.get(`crm-invoice/${id}`)
      .then((res) => {
      data=res.data
        setModal((prev) => ({
          ...prev,
          type: 'edit',
          edit_id: id,
          index: index,
          state: true,
          data: data,
        }))
        setParentValue(data.family.id)
        // getTermsData()
        getDiscountDescription()
        setInvoiceData(data.xero_invoice_no)
        setInvoiceDate(new Date(data.xero_invoice_date))
        setInvoiceDueDate(new Date(data.xero_invoice_due_date))
        let student
      if(data.source=="Teachwork"){
        student=data.family.studentMaster.map((e) =>{return e.id})
      }else{
        student =data.familyInvoiceItem.map((e) =>{return e.studentId})
        }
        setStudentValue(student[0])
        getSubjectData(student[0])
        let payloadData = []
        payloadData = data.familyInvoiceItem.map((l) => {
          return {
            studentId: l.studentId,
            service: l.service ? l.service : null,
            quantity: l.quantity,
            amount: l.cost,
            serviceId: l.crmServiceMaster.id,
            serviceDescription: l.serviceDescription,
          invoiceData:l.xero_invoice_no
          }
        })
        let totalAmount = 0
        for (let i = 0; i < payloadData.length; i++) {
          totalAmount =
            totalAmount + payloadData[i].quantity * payloadData[i].amount
        }
        let PartPayment = 0;
        let CreditNote = 0;
        for (let i = 0; i < data.paymentMaster.length; i++) {
          if(data.paymentMaster[i].paymentMethod == "Credit Note"){
            CreditNote = CreditNote + data.paymentMaster[i].amount
          }
          else{
            PartPayment = PartPayment + data.paymentMaster[i].amount
          }
        }
        setPartPayment(PartPayment)
        setCreditPayment(CreditNote)
        getStudentData(data.family.id)
        setLocationAddress(data.locationId)
        setInvoiceData(data.xero_invoice_no)
        setDescriptionValue(
          data.discountDecription
            ? data.discountDecription.id
            : null
        )
        setDiscount(data.discount)
        setTotalAmount(totalAmount)
        let Tempdicount = data.discount
        if (totalAmount >= Tempdicount) {
          setDiscountError(false)
          totalAmount = Tempdicount ? totalAmount - Tempdicount : totalAmount
        } else {
          setDiscountError(true)
        }
        setAmountPayable(parseInt(data.xero_invoice_balance))
        setPayload(payloadData)
      })
  }

  // useEffect(() => {
  //   const classInfo = setTimeout(() => {
  //     if (studentValue) {
  //       let data = student.filter((l) => l.studentMaster.id == studentValue)
  //       setClassOption(data[0].studentMaster.class.map((e) => e.className))
  //       presentClass(studentValue)
  //     }
  //   }, 500)
  //   return () => clearTimeout(classInfo)
  // }, [studentValue])

  const classInfo = (studentValue)=>{
    if (studentValue) {
      let data = student.filter((l) => l.studentMaster.id == studentValue)
      setClassDate(data[0].studentMaster.class)
      setClassOption(data[0].studentMaster.class.map((e) => e.className))
      presentClass(studentValue)
    }
  }

  const presentClass = (studentValue) => {
    app_api.get(`/family-invoice-item/${studentValue}`)
      .then((res) => {
        const data = res.data
        let payloadData = []
        payloadData = data.map((l) => {
          return {
            studentId: l.studentId,
            service: l.service ? l.service : null,
            quantity: l.quantity,
            amount: l.cost,
            serviceId: l.serviceId,
            serviceDescription: l.serviceDescription,
            invoiceData: l.xero_invoice_no,
            classId: l.classId
          }
        })
        setClassPayload(payloadData)
      }).catch((err) => {
        setError(err?.response?.data?.message || 'error getting data')
      })
  }

  const onEditOpen = async (id, index) => {
    setRenderEditModal(false)
    if (TabData[index].xero_invoice_status == 'PAID' || TabData[index].xero_invoice_status == 'VOIDED') {
      setDisable(false)
      setWholeFormDisable(true)
    }
    setInvoiceDate(new Date(TabData[index].xero_invoice_date))
    setInvoiceDueDate(new Date(TabData[index].xero_invoice_due_date))
    let email = JSON.parse(localStorage.getItem('moldKey')).email
    let link = `/family-master/${TabData[index].family.id}`
    app_api
      .get(link)
      .then((res) => {
        setError(null)
        let data = []
        data.push(res.data)
        setParent(data)
      })
      .catch((err) => {
        setError(err?.response?.data?.message || 'error getting data')
      })
    setParentValue(TabData[index].family.id)
    let paydata = TabData[index]
    setPaymentHistory(paydata)
    const studentId = TabData[index].familyInvoiceItem.map((e) => { return e.studentId })
    let payloadData = []
    payloadData = await Promise.all(TabData[index].familyInvoiceItem.map(async (l) => {
      let submitSchedule, sessionFrequency;
      try {
        if (l.classId == null) {
          return {
            studentId: l.studentId,
            service: l.service ? l.service : null,
            quantity: l.quantity,
            amount: l.cost,
            serviceId: l.crmServiceMaster.id,
            serviceDescription: l.serviceDescription,
            classId: l.classId,
            maxSession: l.quantity
          };
        } else {
          const res = await app_api.get(`class-student/${l.classId}`);
          const data = res.data;
          submitSchedule = data.find(e => e.studentId == l.studentId).className.session;
          const date = data.find(e => e.studentId == l.studentId).createdAt;
          if (submitSchedule.length > 0) {
            sessionFrequency = submitSchedule.sort((a, b) => new Date(a.start_time) - new Date(b.start_time)).filter(e => moment(e.start_time).isSameOrAfter(moment(date), 'day')).length;
          }
          return {
            studentId: l.studentId,
            service: l.service ? l.service : null,
            quantity: l.quantity,
            amount: l.cost,
            serviceId: l.crmServiceMaster.id,
            serviceDescription: l.serviceDescription,
            classId: l.classId,
            maxSession: sessionFrequency
          };
        }
      } catch (err) {
        console.log(err);
      }
    }));
    let totalAmount = 0
    for (let i = 0; i < payloadData.length; i++) {
      totalAmount =
        totalAmount + payloadData[i].quantity * payloadData[i].amount
    }
    let PartPayment = 0
    let CreditNote = 0;
    for (let i = 0; i < paydata?.paymentMaster?.length; i++) {
      if(paydata.paymentMaster[i].paymentMethod == "Credit Note"){
        CreditNote = CreditNote + paydata.paymentMaster[i].amount
      }
      else{
        PartPayment = PartPayment + paydata.paymentMaster[i].amount
      }
    }
    setPartPayment(PartPayment)
    setCreditPayment(CreditNote)
    setStudentValue(studentId[0])
    let classData
    let family_url = `/family-child/${TabData[index].family.id}`
    await app_api
      .get(family_url)
      .then((res) => {
        let data = res.data.filter((l) => l.studentMaster.id == studentId[0])
        classData = data[0].studentMaster.class.map((e) => e.className)
        setClassOption(data[0].studentMaster.class.map((e) => e.className))
        presentClass(studentId[0])
        setClassDate(data[0].studentMaster.class)
      })
      .catch((err) => {
        setError(err?.response?.data?.message || 'error getting data')
      })
    getStudentData(TabData[index].family.id)
    setLocationAddress(TabData[index].locationId)
    setInvoiceData(TabData[index].xero_invoice_no)
    setDescriptionValue(TabData[index].discountDecription? TabData[index].discountDecription.id: null)
    setDiscount(TabData[index].discount)
    setTotalAmount(totalAmount)
    let Tempdicount = TabData[index].discount
    if (totalAmount >= Tempdicount) {
      setDiscountError(false)
      totalAmount = Tempdicount ? totalAmount - Tempdicount : totalAmount
    } else {
      setDiscountError(true)
    }
    setAmountPayable(parseFloat(TabData[index].xero_invoice_balance));
    setPayload(payloadData)
    // console.log(classData)
    //   payloadData2 = TabData[index].familyInvoiceItem.map((l) => {
    //     console.log(l.classId,classData.find((l) => l.id == l.classId)?.session,classData.find((l) => l.id == l.classId)?.sessions)
    //   let submitSchedule = classData.find((l) => l.id == l.classId)?.session?classData.find((l) => l.id == l.classId)?.session:classData.find((l) => l.id == l.classId)?.sessions
    //   console.log(submitSchedule,classData.find((l) => l.id == l.classId)?.session,classData.find((l) => l.id == l.classId)?.sessions,l.classId)
    //     if(submitSchedule.length - l.quantity > 0){
    //       return {
    //         studentId: l.studentId,
    //         service: l.service ? l.service : null,
    //         quantity: l.quantity,
    //         amount: l.cost,
    //         serviceId: l.serviceId,
    //         serviceDescription: l.serviceDescription,
    //         invoiceData: l.xero_invoice_no,
    //         classId: l.classId
    //       }
    //     }
    //   })
    //   console.log(payloadData2)
    // setClassPayload(payloadData)
    getStudentDataByStudentValue(studentId[0])
    setModal((prev) => ({
      ...prev,
      type: 'edit',
      edit_id: id,
      index: index,
      state: true,
      data: TabData[index],
    }))
  }

  const getStudentDataByStudentValue = (id) => {
    app_api.get(`/student-master/${id}`)
      .then((res) => {
      const data=res.data

        setError(null)
      if(data.active == 'Inactive'){
          setDisable(false)
        }
      else{
          setDisable(true)
        }
    })}

  const onMarkAsPaid = (id, index) => {
    const MarkAsPaidData = TabData.filter(e => e.id === id)
    setMarkAsPaidModal((prev) => ({
      ...prev,
      type: 'add',
      edit_id: id,
      index: index,
      paidState: true,
      data: MarkAsPaidData[0],
    }))
    setDiscription(MarkAsPaidData[0].xero_invoice_no)
    setMarkAsPaidPayable(MarkAsPaidData[0].xero_invoice_balance)
  }

  const markPaidTeachwork = (id, index) => {
    let payload = {
      paymentMethod: '',
      updatedBy: JSON.parse(localStorage.getItem('moldKey')).email
    }
    app_api
      .patch(`/crm-invoice/mark-as-paid-invoice/${id}`, payload)
      .then((res) => {
        toast.success('Payment is registered')
        getCRMInvoiceMasterData()
        cleanModalData()
        onFamilyEditOpen(res.data.familyId)
      })
      .catch((err) => {

        setError(err?.response?.data?.message || 'error getting data')
        toast.error('Something went wrong')
      })
  }

  const markCreditNote = (id, index) => {
    const MarkAsCreditNote = TabData.filter(e => e.id === id)
    setCreditModal((prev) => ({
      ...prev,
      type: 'add',
      edit_id: id,
      index: index,
      paidState: true,
      data: MarkAsCreditNote[0],
    }))
    setMarkAsPaidPayable(MarkAsCreditNote[0].xero_invoice_balance)
  }

  const calculateBalance = async () => {
    try {
      let totalAmount = 0
      modal.data?.credit?.family?.crmInvoice?.map((e) => {
        if (data.includes(e.id)) {
          totalAmount += parseInt(e.xero_invoice_balance)
        }
      })
      const balanceAmount = creditAmount - totalAmount
      setBalanceAmount(balanceAmount)
      if (data.length == 0) {
        modal.data.credit?.family?.crmInvoice?.map((e) => {
          e.disable = false
        })
        setBalanceAmount(creditAmount)
      }
      if (balanceAmount <= 0) {
        modal.data.credit?.family?.crmInvoice?.map((e) => {
          if (data.includes(e.id)) {
            e.disable = false
          } else {
            e.disable = true
          }
        })
        setBalanceAmount(0)
      } else {
        modal.data.credit?.family?.crmInvoice?.map((e) => {
          e.disable = false
        })
      }
    } catch (error) {
      console.error(error)
    }
  }

  const handleCheckboxChange = async (id, checked) => {
    data = [...select]
    if (checked) {
      data.push(id)
      setSelect((prevSelectedIds) => [...prevSelectedIds, id])
    } else {
      const index = data.indexOf(id)
      if (index !== -1) {
        data.splice(index, 1)
      }
      setSelect((prevSelectedIds) =>
        prevSelectedIds.filter((item) => item !== id)
      )
    }
    await calculateBalance()
  }

  const onEditCreditOpen = (id, index) => {
    setSelect([])
    app_api.get(`/credit-note/${id}`).then((res) => {
      const data = res.data
      setCreditCreatedAt(data.credit.createdAt)
      setCreditAmount(data.credit.balanceAmount)
      setBalanceAmount(data.credit.balanceAmount)
      setCalculateAmount(data.credit.amount)
      if(data.credit.status != "Issue Refund"){
        setAllocateModal((prev) => ({
          ...prev,
          type: 'edit',
          edit_id: id,
          index: index,
          state: true,
          data: data,
        }))
      }
      else{
        setRefundModal((prev) => ({
          ...prev,
          type: 'edit',
          refund_edit_id: data.credit.refund[0].id,
          index: index,
          refund_state: true,
          data: data,
        }))
      }
    })
  }

  const onRefundOpen = () => {
    const { edit_id, index } = allocateModal
    app_api
      .patch(`credit-note/refund-button/${edit_id}`)
      .then((res) => {
        toast.success('Successfully processed refund request')
        cleanRefundModalData()
        cleanAllocateModalData()
        onFamilyEditOpen(res.data.familyId)

      })
      .catch((err) => {
        toast.error('Failed to process refund request')
        cleanRefundModalData()
      })
  }

  const cleanAllocateModalData = () => {
    setAllocateModal(initialAllocateModalState)
  }

  const cleanRefundModalData = () => {
    setRefundModal(initialRefundState)
  }

  const onRefundMark = (id, index) => {
    setAllocateModal((prev) => ({ ...prev, edit_id: id, index: index }))
    onFamilyEditOpen(allocateModal.data.familyId)
    cleanAllocateModalData()
    setConfirmationRefundDialog(true)
  }

  const renderMarkAsPaidModal = () => {
    const { type, paidState, data } = markAsPaidModal
    return (
      <Formik
        initialValues={data}
        validationSchema={markAsPaidSchema}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          setSubmitting(true)
          let consition1 = values.amount
            ? parseInt(values.amount) <= markAsPaidPayable
            : false
          if (consition1) {
            app_api
              .patch(`/crm-invoice/mark-as-paid-invoice/${data.id}`, { ...values, description: discription })
              .then((res) => {
                toast.success('Payment is registered')
                renderEditModal==true ? onFamilyEditOpen(res.data.customerId):cleanMarkAsPaidModalData()
                getCRMInvoiceMasterData()
                setSubmitting(false)
                cleanModalData()
                resetForm(true)
              })
              .catch((err) => {
                setError(err?.response?.data?.message || 'error getting data')
                resetForm(false)
                setSubmitting(false)
                toast.error('Something went wrong')
              })
          } else {
            toast.error('Amount is greater than Invoice Amount')
            setSubmitting(false)
          }
        }}
      >
        {({
          handleBlur,
          handleChange,
          handleSubmit,
          setValues,
          resetForm,
          values,
          touched,
          isValid,
          isSubmitting,
          errors,
        }) => (
          <Modal
            title="Register a Payment"
            open={paidState}
            setOpen={() => {
              resetForm(true)
              setMarkAsPaidModal((prev) => ({ ...prev, paidState: false }))
              cleanMarkAsPaidModalData()
            }}
          >
            <form onSubmit={handleSubmit} noValidate>
              <div className="text-left mt-2">
                <div className="flex justify-center mb-2">
                  <p>{data.xero_invoice_no}</p>
                </div>
                <div className="flex">
                  <label className="block text-sm font-medium text-gray-700">
                    Date
                  </label>
                  <span className="text-red-700 ml-1">*</span>
                </div>
                <div className="customDatePickerWidth">
                  <DatePicker
                    className="text-left h-9 block w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm border-gray-300 border-1 px-2"
                    name="date"
                    id="date"
                    dateFormat="dd-MM-yyyy"
                    selected={values.date ? values.date : new Date()}
                    onChange={(date) => {
                      setValues((prev) => ({
                        ...prev,
                        date: date,
                      }))
                    }}
                    placeholderText="Payment Date"
                  />
                </div>
                {touched.date && (
                  <p className="text-red-700 error_msg">{errors.date}</p>
                )}
                <div className="grid lg:grid-cols-4 grid-cols-1 lg:gap-4 gap-1 flex flex-wrap">
                  <div className="col-span-2 mt-4">
                    <div className="flex">
                      <label className="block text-sm font-medium text-gray-700">
                        Amount
                      </label>
                      <span className="text-red-700 ml-1">*</span>
                    </div>
                    <CurrencyInput
                      id="amount"
                      name="amount"
                      decimalsLimit={2}
                      value={values.amount}
                      placeholder="Enter amount"
                      autoComplete='off'
                      className="text-left h-9 block w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm border-gray-300 border-1 px-2"
                      prefix="$"
                      onValueChange={(value) => {
                        setValues({
                          ...values,
                          amount: value,
                        })
                      }}
                      onBlur={handleBlur}
                    />
                    {touched.amount && (
                      <p className="text-red-700 error_msg">{errors.amount}</p>
                    )}
                  </div>
                  <div className="col-span-2 mt-4">
                    <div className="flex">
                      <label className="block text-sm font-medium text-gray-700 mt-1">
                        Invoice Amount
                      </label>
                    </div>
                    <CurrencyInput
                      id="amountPayable"
                      name="amountPayable"
                      disabled={true}
                      decimalsLimit={2}
                      value={parseFloat(markAsPaidPayable).toFixed(2)}
                      placeholder="Enter amount"
                      className="text-left h-9 block w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm border-gray-300 border-1 px-2"
                      prefix="$"
                    />
                  </div>
                </div>
                <div className="flex mt-4">
                  <label className="block text-sm font-medium text-gray-700">
                    Method
                  </label>
                  <span className="text-red-700 ml-1">*</span>
                </div>
                <Select
                  className="text-left block w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  name="paymentMethod"
                  id="paymentMethod"
                  isSearchable
                  loadingMessage="Getting Payment Method..."
                  placeholder="Select a payment method..."
                  value={
                    values.paymentMethod
                      ? paymentType?.map((l) => ({
                        value: paymentType.find(
                          (e) => e.id == values.paymentMethod
                        )?.id,
                        label: paymentType.find(
                          (e) => e.id == values.paymentMethod
                        )?.name,
                      }))
                      : null
                  }
                  options={paymentType?.map((l) => ({
                    ...l,
                    label: l.name,
                    value: l.id,
                  }))}
                  onChange={(option) => {
                    setValues({
                      ...values,
                      paymentMethod: option.id,
                    })
                  }}
                  onBlur={handleBlur}
                  menuPlacement="auto"
                  maxMenuHeight={110}
                />
                {touched.paymentMethod && (
                  <p className="text-red-700 error_msg">
                    {errors.paymentMethod}
                  </p>
                )}
                <div className="flex mt-4">
                  <label className="block text-sm font-medium text-gray-700">
                    Description
                  </label>
                </div>
                <textarea
                  name="description"
                  id="description"
                  rows={4}
                  className="border border-gray-300 rounded-md px-1 py-2 w-full"
                  value={discription}
                  onChange={(event) => {
                    setDiscription(event.target.value)
                  }}
                />

                <div className="mt-4 sm:mt-6">
                  <Button
                    type="submit"
                    disabled={isSubmitting}
                    loading={loading}
                    className={"disabled:bg-gray-200"}
                  >
                    {isSubmitting
                      ? 'Registering a Payment...'
                      : 'Register a Payment'}
                  </Button>
                </div>
              </div>
            </form>
          </Modal>
        )}
      </Formik>
    )
  }


  const renderCreditModal = () => {
    const { type, paidState, data } = creditModal
    return (
      <Formik
        initialValues={data}
        validationSchema={creditNoteSchema}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          const email = localStorage.getItem('moldkey')?.email
          app_api
            .patch(`/crm-invoice/creditNote/${data.id}`, { ...values,createdBy: email })
            .then((res) => {

              cleanCreditModalData()
              toast.success('Successfully created credit note')
              cleanModalData()
              renderEditModal==true ? onFamilyEditOpen(data.customerId):getCRMInvoiceMasterData()
              resetForm(true)
            })
            .catch((err) => {

              setError(err?.response?.data?.message || 'error getting data')
              toast.error('Failed to created credit note')
              cleanCreditModalData()
              cleanModalData()
            })
        }}
      >
        {({
          handleBlur,
          handleChange,
          handleSubmit,
          setValues,
          resetForm,
          values,
          touched,
          isValid,
          isSubmitting,
          errors,
          setFieldTouched
        }) => (
          <Modal
            title="Credit Note"
            open={paidState}
            setOpen={() => {
              resetForm(true)
              setCreditModal((prev) => ({ ...prev, paidState: false }))
              cleanCreditModalData()
            }}
          >
            <form onSubmit={handleSubmit} noValidate>
              <div className="text-left mt-2">
                <div className="flex justify-center mb-2">
                  <p>Customer Name: </p>
                  <strong className='px-1'> {data.family?.firstName} {data.family?.lastName}</strong>
                </div>
                <div className="flex">
                  <label className="block text-sm font-medium text-gray-700">
                    Date
                  </label>
                  <span className="text-red-700 ml-1">*</span>
                </div>
                <div className="customDatePickerWidth">
                  <DatePicker
                    className="text-left h-9 block w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm border-gray-300 border-1 px-2"
                    name="date"
                    id="date"
                    dateFormat="dd-MM-yyyy"
                    selected={values.date ? values.date : new Date()}
                    onChange={(date) => {
                      setValues((prev) => ({
                        ...prev,
                        date: date,
                      }))
                    }}
                    placeholderText="Payment Date"
                  />
                </div>
                {/* {touched.date && (
                  <p className="text-red-700 error_msg">{errors.date}</p>
                )} */}
                <div className="grid lg:grid-cols-4 grid-cols-1 lg:gap-4 gap-1 flex flex-wrap">
                  <div className="col-span-4 mt-4">
                    <div className="flex">
                      <label className="block text-sm font-medium text-gray-700">
                        Amount
                      </label>
                      <span className="text-red-700 ml-1">*</span>
                    </div>
                    <CurrencyInput
                      id="amount"
                      name="amount"
                      decimalsLimit={2}
                      value={values.amount}
                      placeholder="Enter amount"
                      className="text-left h-9 block w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm border-gray-300 border-1 px-2"
                      prefix="$"
                      autoComplete='off'
                      onValueChange={(value) => {
                        setValues({
                          ...values,
                          amount: value,
                        })
                      }}
                      onFocus={() => setFieldTouched('amount', false)}
                      onBlur={handleBlur}
                    />
                    {touched.amount && (
                      <p className="text-red-700 error_msg">{errors.amount}</p>
                    )}
                  </div>
                </div>

                <div className="mt-4">
                  < div className="flex">
                    <label className="block text-sm font-medium text-gray-700">
                      Reason for issuing credit note
                    </label>
                    <span className="text-red-700 ml-1">*</span>
                  </div>
                  <Select
                    className="text-left block w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    name="description"
                    id="description"
                    isSearchable
                    isDisabled={values.status == 'PAID'}
                    loadingMessage="Getting Payment Method..."
                    placeholder="Select a reason for issuing credit note..."
                    value={
                      values.description
                        ? reasons?.map((l) => ({
                          value: reasons.find(
                            (e) => e.id == values.description
                          )?.id,
                          label: reasons.find(
                            (e) => e.id == values.description
                          )?.name,
                        }))
                        : null
                    }
                    options={reasons?.map((l) => ({
                      ...l,
                      label: l.name,
                      value: l.id,
                    }))}
                    onChange={(option) => {
                      setValues({
                        ...values,
                        description: option.id,
                      })
                    }}
                    onBlur={handleBlur}
                    menuPlacement="auto"
                    maxMenuHeight={110}
                  />
                  {touched.description && (
                    <p className="text-red-700 mb-2 error_msg">
                      {errors.description}
                    </p>
                  )}
                </div>
                <div className="mt-4 sm:mt-6">
                  <Button
                    type="submit"
                    disabled={isSubmitting   }
                    loading={loading}
                  >
                    Issue credit note
                  </Button>
                </div>
              </div>
            </form>
          </Modal>
        )}
      </Formik>
    )
  }

  useEffect(() => {
  }, [modal])

  useEffect(() => {
  }, [locationAddress, parentValue])

  const onDeleteOpen = (id, index) => {
    const data = editStudent.filter((l) => l.studentMaster.id == studentValue)
    setStudentName(data[0].studentMaster.studentsFirstName)
    setStudentData(data[0].studentMaster)
    setConfirmationDialog(true)
    setUpdateID(id)
  }

  const downloadData = () => {
    setDownloadButtonLoading(true)
    let payload = []
    let Temppayload = {}
    // const today = new Date();
    // const priorTwoMonths = new Date(today.getFullYear(), today.getMonth() - 2, 1)
    // const formattedStartDate = priorTwoMonths.toISOString().slice(0, 10)
    // today.setDate(today.getDate() + 1);
    // const formattedEndDate = today.toISOString().slice(0, 10)
    if (endDate) {
      console.log(true)
      Temppayload.endDate = endDate
    } 
    if (searchFilter) {
      Temppayload.searchText = searchFilter
    } 
    // else {
    //   Temppayload.endDate = formattedEndDate
    // }
    if (startDate) {
      console.log(true)
      Temppayload.startDate = startDate
    } 
    // else {
    //   Temppayload.startDate = formattedStartDate
    // }
    if (locationFilter) {
      Temppayload.locationId = locationFilter.map(value => value.id)
    } else {
      Temppayload.locationId = location.map(value => value.id)
    }
    if (invoiceFilter) {
      Temppayload.xero_invoice_status = invoiceFilter.id
    }
    payload.push(Temppayload)
    if (startDate && !endDate) {
      toast.warning("Please select both start date and end date.")
    }else{
      app_api
        .post(`crm-invoice/download`, payload)
        .then((res) => {
          var link = document.createElement("a");
          link.href = process.env.REACT_APP_STATIC_URL + res.data.path
          link.setAttribute("download", "invoice.csv");
          document.body.appendChild(link); // Required for FF
          link.click();
          setDownloadButtonLoading(false)
        })
        .catch((err) => {
          setError(err.toString())
          toast.error(err.response.data.message)
        })
    }
  }

  let finalPayload = []

  const createPayload = async () => {
    let data = Array.from(payload)
    let studentData = student.find((l) => l.studentMaster.id == studentValue)
      ?.studentMaster?.id
    let studentGrade = classOption.find((l) => l.id == classSelect)
      ?.grade?.id
    if(!studentValue && parentValue) setError(true) 
    else setError(false)
    if(studentValue && !classSelect) setGroupErrors(true)
    else setGroupErrors(false)

    let serviceData = classOption.find(
      (l) =>
        l?.id == classSelect
    )?.service?.id
    let serviceCost = classOption.find(
      (l) =>
        l?.id == classSelect
    )?.service?.cost
    let studentName = student.find((l) => l.studentMaster.id == studentValue)
      ?.studentMaster?.studentsFirstName + ' ' + student.find((l) => l.studentMaster.id == studentValue)?.studentMaster?.studentsLastName
    let noOfClasses = classOption.find((l) => l.id == classSelect)?.frequency
    let sessionFrequency
    let submitSchedule = classOption.find((l) => l.id == classSelect)?.session ? classOption.find((l) => l.id == classSelect)?.session : classOption.find((l) => l.id == classSelect)?.sessions
    await app_api.get(`family-invoice-item/invoices/classId/${classSelect}`)
      .then((res) => {
        const data = res.data
        const date = classDate.find((l) => l.classId == classSelect).createdAt
        const particularStudentData = data.filter((l) => l.studentId == studentValue)
        if (particularStudentData.length > 0) {
          sessionFrequency = submitSchedule.sort((a, b) => new Date(a.start_time) - new Date(b.start_time)).filter(e => moment(e.start_time).isSameOrAfter(moment(date), 'day')).length - particularStudentData.reduce((total, item) => total + item.quantity, 0)
        } else {
          sessionFrequency = submitSchedule.sort((a, b) => new Date(a.start_time) - new Date(b.start_time)).filter(e => moment(e.start_time).isSameOrAfter(moment(date), 'day')).length
        }
      }).catch((err) => {
        console.log(err);
      })
    let subjectName = classOption.find((l) => l.id == classSelect)?.className
    let Temppayload = {
      studentId: studentData,
      service: serviceData
        ? studentName + ' - ' + subjectName
        : null,
      quantity: sessionFrequency <= 0 ? 0 : sessionFrequency,
      amount: serviceCost,
      serviceId: serviceData,
      classId: classOption.find((l) => l.id == classSelect).id,
      serviceDescription: serviceData.description,
      maxSession: sessionFrequency
    }
    if (sessionFrequency > 0) {
      data.push(Temppayload)
    } else {
      toast.warning("No outstanding invoices are associated with this class at the moment.")
    }
    let totalAmount = 0

    for (let i = 0; i < data.length; i++) {
      totalAmount = totalAmount + data[i].quantity * data[i].amount
    }
    setTotalAmount(totalAmount)
    if (totalAmount >= discount) {
      setDiscountError(false)
      totalAmount = discount ? totalAmount - discount : totalAmount
    } else {
      setDiscountError(true)
    }
    // totalAmount = totalAmount + parseInt(charges)
    setAmountPayable(totalAmount)
    let serviceNumber = []
    serviceNumber = data.map((l) => l.serviceId)
    setServiceId(serviceNumber)
    setPayload(data)
    setClassPayload((prev) => [...prev, data].flat())
    setTempServiceValue(null)
    setTermsValue(null)
    setClassSelect([])
    setgroupSizeOptionsValue(null)
  }

  const studentDataChanged = () => {
    setPayload([])
    setClassPayload([])
  }

  const editDescription = (event, index) => {
    let data = Array.from(payload)
    data[index].serviceDescription = event.target.value
    setPayload(data)
  }

  const quantityEdit = (event, index) => {
    let data = Array.from(payload)
    let newQuantity = event.target.value
    if (newQuantity <= data[index].maxSession) {
      data[index].quantity = newQuantity
      if (data[index].quantity == 0 && data[index].quantity != '') {
        toast.warning("Number of sessions cannot be zero.")
      }
    } else {
      toast.warning("Reached the maximum session limit.")
    }

    let totalAmount = 0
    for (let i = 0; i < data.length; i++) {
      totalAmount = totalAmount + data[i].quantity * data[i].amount
    }
    setTotalAmount(totalAmount)
    if (totalAmount >= discount) {
      setDiscountError(false)
      totalAmount = discount ? totalAmount - discount : totalAmount
    } else {
      setDiscountError(true)
    }
    // totalAmount = totalAmount + parseInt(charges)
    setAmountPayable(totalAmount)
    setPayload(data)
  }

  const amountEdit = (value, index) => {
    let data = Array.from(payload)
    data[index].amount = value
    let totalAmount = 0
    for (let i = 0; i < data.length; i++) {
      totalAmount = totalAmount + data[i].quantity * data[i].amount
    }
    setTotalAmount(totalAmount)
    if (totalAmount >= discount) {
      setDiscountError(false)
      totalAmount = discount ? totalAmount - discount : totalAmount
    } else {
      setDiscountError(true)
    }
    // totalAmount = totalAmount + parseInt(charges)
    setAmountPayable(totalAmount)
    setPayload(data)
  }

  const customFilter = (option, searchText) => {
    if (
      option.data.firstName.toLowerCase().includes(searchText.toLowerCase()) ||
      option.data.lastName.toLowerCase().includes(searchText.toLowerCase()) ||
      option.data?.studentMaster[0]?.studentsFirstName.toLowerCase().includes(searchText.toLowerCase())
    ) {
      return true;
    } else {
      return false;
    }
  }

  const removeItems = (index) => {
    let data = Array.from(payload)
    data.splice(index, 1)
    let totalAmount = 0
    for (let i = 0; i < data.length; i++) {
      totalAmount = totalAmount + data[i].quantity * data[i].amount
    }
    setTotalAmount(totalAmount)
    if (totalAmount >= discount) {
      setDiscountError(false)
      totalAmount = discount ? totalAmount - discount : totalAmount
    } else {
      setDiscountError(true)
    }
    let serviceNumber = []
    serviceNumber = data.map((l) => l.serviceId)
    setServiceId(serviceNumber)
    // totalAmount = totalAmount + parseInt(charges)
    setAmountPayable(totalAmount)
    if (data.length == 0) {
      setDiscount(0)
      setDescriptionValue('')
    }
    setPayload(data)
    // setClassPayload((prev)=> [...prev, data].flat())
  }

  const calculateDiscount = (value) => {
    if (value != null && value >= 0) {
      let data = Array.from(payload)
      let totalAmount = 0
      for (let i = 0; i < data.length; i++) {
        totalAmount = totalAmount + data[i].quantity * data[i].amount
      }
      if (totalAmount >= value) {
        setDiscountError(false)
        totalAmount = value ? totalAmount - value : totalAmount
      } else {
        setDiscountError(true)
        totalAmount = totalAmount
      }
      // totalAmount = totalAmount + parseInt(charges)
      setAmountPayable(totalAmount)
      setDiscount(value)
      setPayload(data)
    } else {
      value = 0
      let data = Array.from(payload)
      let totalAmount = 0
      for (let i = 0; i < data.length; i++) {
        totalAmount = totalAmount + data[i].quantity * data[i].amount
      }
      if (totalAmount > value) {
        setDiscountError(false)
        totalAmount = value ? totalAmount - value : totalAmount
      } else {
        setDiscountError(true)
        totalAmount = totalAmount
      }
      setDescriptionValue(null)
      // totalAmount = totalAmount + parseInt(charges)
      setAmountPayable(totalAmount)
      setDiscount(value)
      setPayload(data)
    }
  }

  const getStudentData = (id) => {
    app_api
      .get(`/family-child/${id}`)
      .then((res) => {
        let data = res.data
        let dataCondition = []
        for (let i = 0; i < data.length; i++) {
          if (data[i].studentMaster.active == 'Active') {
            dataCondition.push(true)
          }
        }
        // if(dataCondition.length == data.length){
        //   toast.error('This customer does not have any Active student')
        // }
        data = data.filter(l => l.studentMaster.active == 'Active')
        const editData = res.data.filter(l => l.studentMaster.active == 'Active' || l.studentMaster.active == 'Inactive')
        setError(null)
        setStudent(data)
        setEditStudent(editData)
      })
      .catch((err) => {

        setError(err?.response?.data?.message || 'error getting data')
      })
  }

  const getCustomerData = () => {
    let email = JSON.parse(localStorage.getItem('moldKey')).email
    let url = `/family-master/all?&email=${email}`
    app_api
      .get(url)
      .then((res) => res.data)
      .then((res) => {

        setError(null)
        setCustomer(res.data)
      })
      .catch((err) => {

        setError(err?.response?.data?.message || 'error getting data')
      })
  }

  const getParentData = () => {
    let email = JSON.parse(localStorage.getItem('moldKey')).email
    let url = `/family-master/select?&email=${email}`
    app_api
      .get(url)
      .then((res) => {

        setError(null)
        setParent(res.data)
      })
      .catch((err) => {

        setError(err?.response?.data?.message || 'error getting data')
      })
  }

  const getLocationData = () => {
    let email = JSON.parse(localStorage.getItem('moldKey')).email
    let url = `location/all?&email=${email}`
    app_api
      .get(url)
      .then((res) => res.data)
      .then((res) => {

        setError(null)
        setLocation(res.data)
      })
      .catch((err) => {

        setError(err?.response?.data?.message || 'error getting data')
      })
  }

  const getServiceData = () => {
    app_api
      .get('/crm-service')
      .then((res) => {
        let data = res.data

        setError(null)
        setService(data)
      })
      .catch((err) => {

        setError(err?.response?.data?.message || 'error getting data')
      })
  }

  // const getTermsData = () => {
  //   let email = JSON.parse(localStorage.getItem('moldKey')).email
  //   let url = `/terms-master/all?&email=${email}`
  //   if(locationFilter?.value) url = url+`&location=${locationFilter?.value}`
  //   app_api
  //     .get(url)
  //     .then((res) => res.data)
  //     .then((res) =>
  //     .then((res) => {       
  //       
  //       setError(ll);
  //       setTermData(res.data)
  //     })
  //     .catch((err) => {

  //       setError(err?.response?.data?.message || 'error getting data')
  //     })
  // }

  const getDiscountDescription = () => {
    app_api
      .get('/discount-description')
      .then((res) => {
        let data = res.data

        setError(null)
        setDiscountDescription(data)
      })
      .catch((err) => {

        setError(err?.response?.data?.message || 'error getting data')
      })
  }

  const getSubjectData = (id) => {
    app_api
      .get('subject-master/all')
      .then(res => res.data)
      .then((res) => {

        setError(null)
        let data = res.data.filter(data => data.subject != 'Multiple Subjects' && data.subject != 'VCE Multiple Subjects');
        setSubject(data)
        filterVCEEditOpen(id, data)
      })
      .catch((err) => {

        setError(err?.response?.data?.message || 'error getting data')
      })
  }

  const handleClickDiv = () => {
    if (!studentValue) {
      setError(true)
    } else if (!classSelect) {
      setGroupErrors(true)
    } else {
      createPayload()
    }
  }

  useEffect(() => {
    getCRMInvoiceMasterData()
    getServiceData()
    getLocationData()
    getParentData()
    getCustomerData()
    getSubjectData()
    getLocationManagerData()
    // getTermsData()
    getDiscountDescription()
    const date = invoiceDueDate
    date.setDate(date.getDate() + 7)
    setInvoiceDueDate(date)
  }, [])


  const onFamilyEditOpen = (id) => {
    app_api
      .get(`/family-master/${id}`)
      .then((res) => {
        let data = res.data
        if (data.parentState) {
          let countryCodeName = studentState.find(
            (l) => l.name == data.parentState
          )?.countryCode
          let isoCodeName = studentState.find(
            (l) => l.name == data.parentState
          )?.isoCode
          getCities(countryCodeName, isoCodeName)
        }
        setFamilyModal((prev) => ({
          ...prev,
          type: 'edit',
          edit_id: id,
          index: null,
          state: true,
          data: data,
        }))
        setFamilyDetails(data)
        setParentEnabled(data.active)
      })
  }

  const cleanFamilyModalData = () => {
    setFamilyModal(initialFamilyModalState)
  }

  const handleParentCheckedChanges = () => {
    if (parentEnabled == 'Prospective') {
      setParentEnabled('Active')
    } else {
      if (parentEnabled == 'Inactive') {
        setParentEnabled('Active')
      } else {
        if (parentEnabled == 'Active') {
          setParentEnabled('Inactive')
        } else {
          setParentEnabled('Inactive')
        }
      }
    }
  }

  const onStudentEditOpen = (id, index) => {
    app_api.get(`/student-master/${id}`)
      .then((res) => {
        const data = res.data

        setError(null)
        let payload = {
          firstName: data?.studentsFirstName,
          lastName: data?.studentsLastName,
          email: data?.studentsEmail,
          selectFamily: data?.familyId,
          grade: data?.grade,
          mobileNumber: data?.studentPhone,
          vce: data?.gradeMaster.vce,
          class: data?.class,
          att: data?.attendance,
          center: data?.locations.map(l => l.locationId)
        }

        setStudentModal((prev) => ({
          ...prev,
          type: 'edit',
          edit_id: id,
          index: index,
          modalState: true,
          data: payload,
        }))
        setStudentEnabled(data.active)
      })
    getGradeData()
  }

  const handleStudentCheckedChanges = () => {
    if (studentEnabled == 'Prospective') {
      setStudentEnabled('Active')
    } else {
      if (studentEnabled == 'Inactive') {
        setStudentEnabled('Active')
      } else {
        if (studentEnabled == 'Active') {
          setStudentEnabled('Inactive')
        } else {
          setStudentEnabled('Inactive')
        }
      }
    }
  }

  useEffect(() => {
    getStateDataByBackend()
    getColumns()
  }, [])

  const getStateDataByBackend = () => {
    app_api
      .post('/state-city/state')
      .then((res) => {
        let data = res.data
        let defaultState = data.filter((l) => l.name == 'Victoria')
        setDefaultState(defaultState)
        setStudentState(data)

        setError(null)
      })
      .catch((err) => {

        setError(err?.response?.data?.message || 'error getting data')
      })
  }

  useEffect(() => {
    let payload = {
      countryCode: defaultState[0]?.countryCode.toString(),
      isoCode: defaultState[0]?.isoCode.toString(),
    }
    app_api
      .post('/state-city/city', payload)
      .then((res) => {
        let data = res.data
        if (payload.isoCode === 'VIC') {
          data.push({
            countryCode: 'AU',
            latitude: '-37.66955000',
            longitude: '144.88675000',
            name: 'Lyndhurst',
            stateCode: 'VIC',
          })
        }
        setStudentCity(data)

        setError(null)
      })
      .catch((err) => {

        setError(err?.response?.data?.message || 'error getting data')
      })
  }, [defaultState])

  const getCities = (countryCode, isoCode) => {
    let payload = {
      countryCode: countryCode?.toString(),
      isoCode: isoCode?.toString(),
    }
    app_api
      .post('/state-city/city', payload)
      .then((res) => {
        let data = res.data
        if (isoCode === 'VIC') {
          data.push({
            countryCode: 'AU',
            latitude: '-37.66955000',
            longitude: '144.88675000',
            name: 'Lyndhurst',
            stateCode: 'VIC',
          })
        }
        setStudentCity(data)

        setError(null)
      })
      .catch((err) => {

        setError(err?.response?.data?.message || 'error getting data')
      })
  }

  const checkPostCode = (postCode, state) => {
    if (state != undefined || postCode != undefined) {
      let booleanValue
      if (state == 'Australian Capital Territory') {
        if (postCode.startsWith('02'))
          booleanValue =
            parseInt(postCode) >= parseInt('0200') &&
            parseInt(postCode) <= parseInt('0299')
        else if (parseInt(postCode) >= 2600 && parseInt(postCode) <= 2618)
          booleanValue = true
        else if (parseInt(postCode) >= 2900 && parseInt(postCode) <= 2920)
          booleanValue = true
        else booleanValue = false
      }
      if (state == 'New South Wales') {
        if (parseInt(postCode) >= 1000 && parseInt(postCode) <= 2599)
          booleanValue = true
        else if (parseInt(postCode) >= 2619 && parseInt(postCode) <= 2899)
          booleanValue = true
        else if (parseInt(postCode) >= 2921 && parseInt(postCode) <= 2999)
          booleanValue = true
        else booleanValue = false
      }
      if (state == 'Northern Territory') {
        if (postCode.startsWith('08'))
          booleanValue =
            parseInt(postCode) >= parseInt('0800') &&
            parseInt(postCode) <= parseInt('0899')
        if (postCode.startsWith('09'))
          booleanValue =
            parseInt(postCode) >= parseInt('0900') &&
            parseInt(postCode) <= parseInt('0999')
        else booleanValue = false
      }
      if (state == 'Queensland') {
        if (parseInt(postCode) >= 4000 && parseInt(postCode) <= 4999)
          booleanValue = true
        else if (parseInt(postCode) >= 9000 && parseInt(postCode) <= 9999)
          booleanValue = true
        else booleanValue = false
      }
      if (state == 'South Australia') {
        if (parseInt(postCode) >= 5000 && parseInt(postCode) <= 5999)
          booleanValue = true
        else booleanValue = false
      }
      if (state == 'Tasmania') {
        if (parseInt(postCode) >= 7000 && parseInt(postCode) <= 7999)
          booleanValue = true
        else booleanValue = false
      }
      if (state == 'Victoria') {
        if (parseInt(postCode) >= 3000 && parseInt(postCode) <= 3999)
          booleanValue = true
        else if (parseInt(postCode) >= 8000 && parseInt(postCode) <= 8999)
          booleanValue = true
        else booleanValue = false
      }
      if (state == 'Western Australia') {
        if (parseInt(postCode) >= 6000 && parseInt(postCode) <= 6797)
          booleanValue = true
        else if (parseInt(postCode) >= 6800 && parseInt(postCode) <= 6999)
          booleanValue = true
        else booleanValue = false
      }
      if (postCode == '') {
        setPostCodeError(null)
        booleanValue = true
      }

      if (booleanValue) {
        setPostCodeError(null)
        return true
      } else {
        setPostCodeError('Invalid postcode')
      }
    }
    else {
      return true
    }
  }

  const handleInputChange = characterEntered => {
    let familysearchfilter = characterEntered
    setCustomerData(familysearchfilter)
  }

  const cleanStudentModalData = () => {
    setStudentModal(initialStudentModalState)
  }

  const generateInvoicePdf = () => {
    let invPayload = {
      customerId: parentValue,
      invoiceDate: moment(new Date()).format('DD-MM-YYYY'),
      dueDate: moment(invoiceDueDate).format('DD-MM-YYYY'),
      servicePayload: payload,
      amountPayable: amountPayable,
      discount: discount
    }
    app_api.post(`crm-invoice/generate/invoicepreview`, invPayload)
      .then((res) => {
        var encodedUri = encodeURI(res.data);
        var link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("target", "_blank");
        link.setAttribute("download", `Invoice-preview.pdf`);
        document.body.appendChild(link);
        link.click();
      }).catch((err) => {
        toast.error('Failed to preview')
      })
  }

  const getLocationManagerData = () => {
    app_api
      .get('users/manager-locations')
      .then((res) => {
        let data = res.data.filter(l => l.is_active == true);
        setLoading(false)
        setError(null)
        setLocationManager(data)
      })
      .catch((err) => {
        setLoading(false)
        setError(err?.response?.data?.message || 'error getting data')
      })
  }

  const getAbnValue = (abnValue) => {
    let guid = "220fe5cd-f965-4f2a-93ce-61fa1626d8cb";
    const url = `https://abr.business.gov.au/json/AbnDetails.aspx?abn=${abnValue}&callback=callback&guid=${guid}`;
    fetch(url)
      .then(response => response.text())
      .then(data => {
        const jsonData = extractJsonData(data);
        if (jsonData.Abn) {
          setGstValue(jsonData.Gst);
          setAbnStatus(jsonData.AbnStatus)
          setEntityName(jsonData.EntityName)
          setAbnError(null);
        }
        else {
          setAbnError('Business not found');
        }

      })
      .catch(error => {
        console.error('An error occurred:', error);
      });

  }
  function extractJsonData(responseText) {
    const startIndex = responseText.indexOf('{');
    const endIndex = responseText.lastIndexOf('}');
    const jsonString = responseText.substring(startIndex, endIndex + 1);
    const jsonData = JSON.parse(jsonString);
    return jsonData;
  }

  const onEditLocationOpen = (id, index) => {
    const data = location.filter((e) => e.id == id)
    setLocationModal((prev) => ({
      ...prev,
      type: 'edit',
      edit_id: id,
      index: index,
      state: true,
      data: data[0],
    }))
    if (data[0].state) {
      let countryCodeName = studentState.find(l => l.name == data[0].state)?.countryCode
      let isoCodeName = studentState.find(l => l.name == data[0].state)?.isoCode
      getCities(countryCodeName, isoCodeName)
    }
  }

  const renderModal = () => {
    const { type, state, edit_id, data } = modal
    const { customerId, date } = data
    return (
      <Formik
        initialValues={data}
        validationSchema={null}
        enableReinitialize
        onSubmit={(values, { setSubmitting, resetForm }) => {
          if (discountError == false) {
            if (discount > 0) {
              if (descriptionValue != null) {
                if (type == 'add') {
                  finalPayload = {
                    customerId: parentValue,
                    invoiceDate: invoiceDate,
                    dueDate: invoiceDueDate,
                    servicePayload: payload,
                    subtotal: totalAmount,
                    discount: discount,
                    amountPayable: amountPayable,
                    serviceId: serviceId,
                    descriptionId: parseInt(descriptionValue),
                    locationId: values.locationId,
                    typeOfService: values.typeOfService,
                    // charges: charges,
                    createdBy: JSON.parse(localStorage.getItem('moldKey')).email
                  }
                  app_api
                    .post('/enrolment/testEnrolment', finalPayload)
                    .then((res) => {
                      setSubmitting(false)
                      resetForm()
                      cleanModalData()
                      toast.success('Invoice Created Successfully')
                      getCRMInvoiceMasterData()
                    })
                    .catch((err) => {
                      if (err.response.status == 424) {
                        toast.error('Duplicate Entry')
                      } else {
                        toast.error('Something Went Wrong')
                      }
                      cleanModalData()
                      setSubmitting(false)
                    })
                } else {
                  finalPayload = {
                    customerId: parentValue,
                    invoiceDate: invoiceDate,
                    dueDate: invoiceDueDate,
                    servicePayload: payload,
                    subtotal: totalAmount,
                    discount: discount,
                    amountPayable: amountPayable,
                    serviceId: serviceId,
                    descriptionId: parseInt(descriptionValue),
                    locationId: values.locationId,
                    typeOfService: values.typeOfService,
                    // charges: charges,
                    updatedBy: JSON.parse(localStorage.getItem('moldKey')).email
                  }
                  app_api
                    .patch(`/enrolment/testEnrolment/${edit_id}`, finalPayload)
                    .then((res) => {
                      getCRMInvoiceMasterData()
                      setSubmitting(false)
                      resetForm()
                      renderEditModal === true ? onFamilyEditOpen(res.data.customerId) : cleanModalData()
                      toast.success('Invoice Updated Successfully')
                    })
                    .catch((err) => {
                      if (err.response.status == 424) {
                        toast.error('Duplicate Entry')
                      } else {
                        toast.error('Something Went Wrong')
                      }
                      cleanModalData()
                      setSubmitting(false)
                    })
                }
              } else {
                toast.error('Select a discount description')
                setSubmitting(false)
              }
            } else {
              if (type == 'add') {
                finalPayload = {
                  customerId: parentValue,
                  invoiceDate: invoiceDate,
                  dueDate: invoiceDueDate,
                  servicePayload: payload,
                  subtotal: totalAmount,
                  discount: discount,
                  amountPayable: amountPayable,
                  serviceId: serviceId,
                  descriptionId: parseInt(descriptionValue),
                  locationId: values.locationId,
                  typeOfService: values.typeOfService,
                  // charges: charges,
                  createdBy: JSON.parse(localStorage.getItem('moldKey')).email
                }
                app_api
                  .post('/enrolment/testEnrolment', finalPayload)
                  .then((res) => {
                    setSubmitting(false)
                    resetForm()
                    cleanModalData()
                    toast.success('Invoice Created Successfully')
                    getCRMInvoiceMasterData()
                    renderEditModal === true ? onFamilyEditOpen(res.data.customerId) : cleanModalData()
                  })
                  .catch((err) => {
                    if (err.response.status == 424) {
                      toast.error('Duplicate Entry')
                    } else {
                      toast.error('Something Went Wrong')
                    }
                    cleanModalData()
                    setSubmitting(false)
                  })
              } else {
                finalPayload = {
                  customerId: parentValue,
                  invoiceDate: invoiceDate,
                  dueDate: invoiceDueDate,
                  servicePayload: payload,
                  subtotal: totalAmount,
                  discount: discount,
                  amountPayable: amountPayable,
                  serviceId: serviceId,
                  descriptionId: parseInt(descriptionValue),
                  locationId: values.locationId,
                  typeOfService: values.typeOfService,
                  // charges: charges,
                  updatedBy: JSON.parse(localStorage.getItem('moldKey')).email
                }
                app_api
                  .patch(`/enrolment/testEnrolment/${edit_id}`, finalPayload)
                  .then((res) => {
                    setSubmitting(false)
                    resetForm()
                    cleanModalData()
                    toast.success('Invoice Updated Successfully')
                    getCRMInvoiceMasterData()
                  })
                  .catch((err) => {
                    if (err.response.status == 424) {
                      toast.error('Duplicate Entry')
                    } else {
                      toast.error('Something Went Wrong')
                    }
                    cleanModalData()
                    setSubmitting(false)
                  })
              }
            }
          } else {
            toast.error('Discount is greater than total amount')
            setSubmitting(false)
          }
        }}
      >
        {({
          handleBlur,
          Formik,
          handleChange,
          handleSubmit,
          setValues,
          resetForm,
          values,
          touched,
          isValid,
          isSubmitting,
          errors,
        }) => (
          <BigSlideover
            open={state}
            setOpen={() => {
              setModal((prev) => ({ ...prev, modalState: false }))
              cleanModalData()
              setClassPayload([])
              // resetForm(true)
              setValues({
                ...values,
                locationId: '',
                typeOfService: ''
              })
            }}
            title={type === 'add' ? 'Create Invoice' : `${invoiceData}`}
            handleClick={''}
            data={data}
            resendInvoice={() => onResendMail(edit_id)}
          >
            <form onSubmit={handleSubmit} noValidate>
              <div className="-mt-6 text-left">
                <div className="flex justify-between items-center space-x-2">
                  <div className="w-1/4">
                    <div className="flex ">
                      <label className="block text-sm font-medium text-gray-700">
                        Select a Customer
                      </label>
                      <span className="text-red-700 ml-1">*</span>
                    </div>
                    <div className="">
                      <Select
                        className="text-left block w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                        name="customerId"
                        id="customerId"
                        isSearchable
                        loadingMessage="Getting Customer..."
                        placeholder="Select a customer..."
                        value={
                          parentValue
                            ? parent.map((l) => ({
                              value: parent.find((e) => e.id == parentValue)
                                ?.id,
                              label:
                                parent.find((e) => e.id == parentValue)
                                  ?.lastName +
                                ', ' +
                                parent.find((e) => e.id == parentValue)
                                  ?.firstName,
                            }))
                            : null
                        }
                        filterOption={customFilter}
                        onInputChange={handleInputChange}
                        options={customer?.map((l) => ({
                          ...l,
                          label: l.lastName + ', ' + l.firstName,
                          value: l.id,
                        }))}
                        onChange={(option) => {
                          let value = []
                          value.push(option)
                          setParent(value)
                          setValues({
                            ...values,
                            customerId: option.value,
                            locationId: '',
                            typeOfService: '',
                          })
                          setStudentValue()
                          // setLocationAddress(option.center)
                          setParentValue(option.value)
                          setPayload([])
                          getStudentData(option.value)
                        }}
                        onBlur={handleBlur}
                        menuPlacement="auto"
                        isDisabled={type == 'edit' || wholeFormDisable}
                        styles={{
                          singleValue: (provided) => ({
                            ...provided,
                            fontWeight: 'bold',
                            fontSize: '14px',
                          })
                        }}
                      />
                    </div>
                  </div>
                  <div className="w-1/4 ">
                    <div className="flex ">
                      <label className="block text-sm font-medium text-gray-700">
                        Select a Student
                      </label>
                      <span className="text-red-700 ml-1">*</span>
                    </div>
                    <div className="">
                      <Select
                        className="text-left block w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                        isSearchable
                        loadingMessage="Getting Student..."
                        placeholder="Select a student..."
                        value={
                          studentValue
                            ? editStudent.map((l) => ({
                              value: editStudent.find(
                                (e) => e.studentMaster.id == studentValue
                              )?.studentMaster?.id,
                              label: editStudent.find((e) => e.studentMaster.id == studentValue)?.studentMaster?.studentsLastName +
                                ', ' +
                                editStudent.find((e) => e.studentMaster.id == studentValue)
                                  ?.studentMaster?.studentsFirstName,
                            }))
                            : null
                        }
                        options={student?.map((l) => ({
                          ...l,
                          label: l.studentMaster.studentsLastName + ', ' + l.studentMaster.studentsFirstName,
                          value: l.studentMaster?.id,
                        }))}
                        isDisabled={type == 'edit' ? true : !parentValue}
                        onChange={(option) => {
                          setValues({
                            ...values,
                            studentLocation: option.studentMaster.locations.map((l) => l.locationId),
                            locationId: option.studentMaster.locations[0].locationId
                          })
                          // setLocationAddress(parseInt(option.studentMaster.center))
                          setStudentValue(option.value)
                          classInfo(option.value)
                          presentClass(option.value)
                          setError(false)
                          // studentDataChanged()
                        }}
                        onBlur={handleBlur}
                        menuPlacement="auto"
                        maxMenuHeight={110}
                        styles={{
                          singleValue: (provided) => ({
                            ...provided,
                            fontWeight: 'bold',
                            fontSize: '14px',
                          })
                        }}
                      />
                    </div>
                  </div>
                  <div className="w-1/4 ">
                    <div className="flex">
                      <label className="block text-sm font-medium text-gray-700">
                        Type of Service
                      </label>
                      <span className="text-red-700 ml-1">*</span>
                    </div>
                    <Select
                      className="text-left block w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      name="typeOf"
                      id="typeOf"
                      isSearchable
                      loadingMessage="Getting Group Size..."
                      placeholder="Select a type of service..."
                      value={
                        values.typeOfService
                          ? typeOfServiceOptions?.map((l) => ({
                            value: typeOfServiceOptions?.find(
                              (e) => e.id == values.typeOfService
                            )?.id,
                            label: typeOfServiceOptions?.find(
                              (e) => e.id == values.typeOfService
                            )?.name,
                          }))
                          : null
                      }
                      options={typeOfServiceOptions.map((l) => ({
                        ...l,
                        label: l.name,
                        value: l.id,
                      }))}
                      onChange={(option) => {
                        setValues({
                          ...values,
                          typeOfService: option.value,
                        })
                        studentDataChanged()
                      }}
                      onBlur={handleBlur}
                      menuPlacement="auto"
                      maxMenuHeight={110}
                      isDisabled={!studentValue || type == 'edit'}
                      styles={{
                        singleValue: (provided) => ({
                          ...provided,
                          fontWeight: 'bold',
                          fontSize: '14px',
                        })
                      }}
                    />
                    {touched.typeOf && (
                      <p className="text-red-700 error_msg ">
                        {errors.typeOf}
                      </p>
                    )}
                  </div>
                  <div className="w-2/4 mt-4 flex flex-col justify-end">
                    <div className="flex justify-end">
                      <div className="w-2/6">
                        <div className="">
                          <label className="text-sm font-medium text-gray-700">
                            Select Location
                          </label>
                          <span className="text-red-700 ml-1">*</span>
                        </div>
                        <Select
                          className="text-left w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-white shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                          name="locationAddress"
                          id="locationAddress"
                          isSearchable
                          loadingMessage="Getting Location..."
                          placeholder="Select a location..."
                          value={
                            values.locationId
                              ? location?.map((l) => ({
                                value: location?.find(
                                  (e) => e.id == values.locationId
                                )?.id,
                                label: location?.find(
                                  (e) => e.id == values.locationId
                                )?.name,
                              }))
                              : null
                          }
                          isDisabled={type == 'edit' ? true : !studentValue}
                          options={location?.filter(l => values?.studentLocation?.includes(l.id))?.map((l) => ({
                            ...l,
                            label: l.name,
                            value: l.id,
                          }))}
                          onChange={(option) => {
                            // setLocationAddress(option.value)
                            setValues({
                              ...values,
                              locationId: option.value,
                            })
                            setPayload([])
                            setClassPayload([])
                            setClassSelect([])
                          }}
                          onBlur={handleBlur}
                          menuPlacement="auto"
                          maxMenuHeight={110}
                          styles={{
                            singleValue: (provided) => ({
                              ...provided,
                              fontWeight: 'bold',
                              fontSize: '16px',
                            })
                          }}
                        />
                      </div>
                    </div>
                    <div className="flex justify-end mt-1">
                      <p className="w-2/6 font-semibold text-sm">
                        {values.locationId &&
                          location.find((l) => l.id == values.locationId)
                            ?.address}
                        <br />
                        {values.locationId &&
                          location.find((l) => l.id == values.locationId)
                            ?.city}{' '}
                        {values.locationId &&
                          location.find((l) => l.id == values.locationId)
                            ?.state}{' '}
                        {values.locationId &&
                          location.find((l) => l.id == values.locationId)
                            ?.postCode}
                      </p>
                    </div>
                  </div>
                  <div className="w-1/4 h-20 flex justify-center items-center mt-3">
                    <img className="w-48" src={logo} alt="Your Company" />
                  </div>
                </div>
                {parentValue ? (
                  <>
                    <div className="w-1/4 mt-1 mb-3 font-semibold text-sm">
                      {parent.find((l) => l.id == parentValue)?.address1}
                      <br />
                      <span>
                        {parent.find((l) => l.id == parentValue)?.city}{' '}
                        {parent.find((l) => l.id == parentValue)?.parentState}{' '}
                        {parent.find((l) => l.id == parentValue)?.postCode}
                      </span>
                      <br />
                      <span>
                        {parent
                          .find((l) => l.id == parentValue)
                          ?.country?.slice(0)
                          .toUpperCase()}
                      </span>
                    </div>
                  </>
                ) : null}
                {/* {touched.category && (
                <p className="text-red-700 error_msg">{errors.category}</p>
              )} */}

                <div className="flex items-center text-left">
                  <div className="w-1/4">
                    <div className="flex">
                      <label className="block text-sm font-medium text-gray-700">
                        Invoice Date
                      </label>
                      <span className="text-red-700 ml-1">*</span>
                    </div>
                    <DatePicker
                      className="text-left h-9 block w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm border-gray-300 border-1 px-2"
                      name="date"
                      dateFormat="dd-MM-yyyy"
                      id="date"
                      selected={invoiceDate}
                      disabled={true}
                      isClearable={false}
                      onChange={(date) => {
                        setValues((prev) => ({
                          ...prev,
                          date: date,
                        }))
                      }}
                      placeholderText="Date"
                    />
                  </div>

                  <div className="w-1/4 ml-4">
                    <div className="flex">
                      <label className="block text-sm font-medium text-gray-700">
                        Due Date
                      </label>
                      <span className="text-red-700 ml-1">*</span>
                    </div>
                    <DatePicker
                      className="text-left h-9 block w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm border-gray-300 border-1 px-2"
                      name="invoiceDueDate"
                      dateFormat="dd-MM-yyyy"
                      id="invoiceDueDate"
                      selected={invoiceDueDate}
                      disabled={type == 'edit' ? true : false}
                      onChange={(date) => {
                        setValues((prev) => ({
                          ...prev,
                          dueDate: date,
                        }))
                        setInvoiceDueDate(date)
                      }}
                      minDate={new Date()}
                      isClearable={type == 'edit' ? false : true}
                      placeholderText="Due Date"
                    />
                    {touched.categoryType && (
                      <p className="text-red-700 error_msg">
                        {errors.categoryType}
                      </p>
                    )}
                  </div>
                </div>
              </div>
              {/* Spacer */}
              <div className="mt-4"></div>
              {/* Spacer */}
              {modal.data.source != 'Teachwork' && (
                <div className="flex items-center bg-stone-300 h-12 rounded-t-md">
                  <div className="w-1/4 ml-4 text-black font-semibold text-md">
                    <span>Class</span>
                  </div>
                  {/* <div className="w-2/4 ml-4 text-black font-semibold text-md">
                  <span>Description</span>
                </div> */}
                  <div className="w-1/4 ml-4 text-black font-semibold text-md text-center">
                    <span>Number of Sessions</span>
                  </div>
                  <div className="w-1/4 ml-4 text-black font-semibold text-md text-center">
                    <span>Unit Price</span>
                  </div>
                  <div className="w-1/4 mr-4 text-black font-semibold text-md text-center">
                    <span>Amount</span>
                  </div>
                  {!wholeFormDisable && modal.data.xero_invoice_balance == modal.data.amountPayable && !modal.data.familyInvoiceItem?.map(l => l.service).includes('Admin Charge') && payload.length > 1 && (
                    <div className="w-10 mr-4 text-black font-semibold text-md text-right"></div>
                  )}
                </div>)}
              {payload != null && payload.length > 0 ? (
                <>
                  <div className="max-h-40 overflow-auto bg-slate-50 rounded-b-md shadow-md">
                    {payload.map((item, index) => (
                      <>
                        <div className="flex items-center h-10">
                          <div className="w-1/4 ml-4 text-black text-sm">
                            <span>{item.service}</span>
                          </div>
                          {/* <div className="w-2/4 ml-4 text-black text-sm">
                            <input
                              type="text"
                              name="description"
                              value={item?.serviceDescription}
                              onChange={(e) => {
                                editDescription(e, index)
                              }}
                              disabled={wholeFormDisable}
                              onBlur={handleBlur}
                              className="w-full appearance-none sm:text-sm py-3 focus:bg-none focus:outline-none focus:ring-none bg-slate-50"
                            />
                          </div> */}
                          <div className="w-1/4 ml-4 text-black text-sm">
                            {/* <span>{item.quantity}</span> */}
                            <input
                              type="number"
                              name="quantity"
                              min={1}
                              value={item?.quantity}
                              onChange={(e) => {
                                quantityEdit(e, index)
                              }}
                              max={item?.maxSession}
                              disabled={wholeFormDisable}
                              onBlur={handleBlur}
                              className="w-full text-center appearance-none sm:text-sm px-2 py-3 focus:bg-none focus:outline-none focus:ring-none bg-slate-50"
                            />
                          </div>
                          <div className="w-1/4 ml-4 text-black text-sm text-center">
                            <CurrencyInput
                              id="amount"
                              name="amount"
                              decimalsLimit={2}
                              value={item?.amount}
                              className="w-full text-center appearance-none sm:text-sm px-2 py-3 focus:bg-none focus:outline-none focus:ring-none bg-slate-50"
                              prefix="$"
                              onValueChange={(value) => {
                                amountEdit(value, index)
                              }}
                            />
                          </div>
                          <div className="w-1/4 mr-4 text-black text-sm text-center">
                            <span>$ {(item.amount * item.quantity).toFixed(2)}</span>
                          </div>
                          {!wholeFormDisable && modal.data.xero_invoice_balance == modal.data.amountPayable && !modal.data.familyInvoiceItem?.map(l => l.service).includes('Admin Charge') && payload.length > 1 && (
                            <div
                              className="w-10 mr-4 text-red-400 hover:text-red-500 duration-300 cursor-pointer font-semibold text-md"
                              onClick={() => {
                                removeItems(index)
                              }}
                            >
                              <XCircleIcon className="w-6 h-6" />
                            </div>
                          )}
                        </div>
                        <hr className="text-slate-400"></hr>
                      </>
                    ))}
                  </div>
                </>
              ) : (
                <>
                  {modal.data.source == 'Teachwork' && modal.data.xero_invoice_balance != 0 && (
                    <>
                      <div className=" h-10 bg-orange-200 rounded-lg items-center ">
                        <div className="ml-4 text-black text-lg flex justify-center items-center place-content-center text-center ">
                          <div className='text-center mt-2'>
                            <InformationCircleIcon className='w-6 text-red-400 ' />
                          </div>
                          <div className='text-center mt-2'>
                            <span className="">This invoice was created in Teachworks. You can only register a payment here.</span>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                  {modal.data.source == 'Teachwork' && modal.data.xero_invoice_balance == 0 && (
                    <>
                      <div className=" h-10 bg-green-200 rounded-lg items-center ">
                        <div className="ml-4 text-black text-lg flex justify-center items-center place-content-center text-center ">
                          <div className='text-center mt-2'>
                            <CheckCircleIcon className='w-6 text-green-800 ' />
                          </div>
                          <div className='text-center mt-2'>
                            <span className="">PAID</span>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </>
              )}
              {!wholeFormDisable && modal.data.source != 'Teachwork' && modal.data.xero_invoice_balance == modal.data.amountPayable && disable != false && !modal.data.familyInvoiceItem?.map(l => l.service).includes('Admin Charge') && (
                <>
                  <div className="flex items-center h-12 mt-3">
                    <div className="w-3/12 text-black text-md">
                      <Select
                        className="text-left block w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                        isSearchable
                        loadingMessage="Getting Class..."
                        placeholder="Select class..."
                        value={
                          classSelect
                          ? {value: classOption?.find((e) => e.id == classSelect)?.id,
                              label: classOption?.find((e) => e.id == classSelect)?.className,}
                            : null
                        }
                        options={classOption?.filter((l) =>
                          // !classPayload?.some((f) => f.classId === l.id) && 
                          l.locationId == values.locationId && l.service.typeOf == values.typeOfService).map((l) => ({
                            ...l,
                            label: l?.className,
                            value: l?.id,
                          }))}
                        onChange={(option) => {
                          setValues({
                            ...values,
                            classSelect: option.value,
                          })
                          setClassSelect(option.value)
                        }}
                        isDisabled={!values.typeOfService}
                        onBlur={handleBlur}
                        menuPlacement="auto"
                        maxMenuHeight={110}
                      />
                    </div>
                    <div
                      className="ml-4 h-8 w-8 flex items-center justify-center rounded-full bg-indigo-900 text-white"
                    onClick={() => {handleClickDiv() }}
                    >
                      <PlusIcon className="w-6 h-6" />
                    </div>
                  </div>
                  <div className="flex">

                    <div className="w-1/6">
                      {groupErrors && (
                        <>
                          <p className="text-sm text-yellow-600">Please select a class</p>
                        </>
                      )}
                    </div>

                    {/* <div className="w-1/6 ml-4">
                      {gradeError && (
                        <>
                          <p className="text-sm text-yellow-600 text-right">No Grade details were found for the student, Please provide the same.</p>
                        </>
                      )}
                    </div> */}
                  </div>
                </>
              )}
              <div className='flex justify-between items-center mt-14 mb-4'>
                <div className="flex w-1/2">
                  <div className="left-auto text-justify">
                    <p className="text-semibold text-md text-gray-500">
                      Terms and Condition
                    </p>
                    <p className="text-sm text-gray-400">
                      All invoices must be paid within 7 days of issue. <br />
                      You can pay by credit card by clicking on the link provided
                      in the invoice. <br />
                      Please note, payment done by Credit Card will incur a
                      service charge by the provider.
                      <br />
                      To avoid the surcharge, you may pay us directly into our
                      account or pay by cash. <br />
                      Our account details are given below. <br />
                      <b>
                        <u>
                          Please make sure that you put the invoice number in the
                          reference when doing a bank transfer
                        </u>
                      </b>
                      .
                    </p>
                    <br></br>
                    {values.locationId && (
                      <>
                        <p className="text-sm text-gray-500 mb-4">
                          Account Name:{' '}
                          {
                            location.find((l) => l.id == values.locationId)
                              ?.accountName
                          }{' '}
                          <br />
                          BSB:{' '}
                          {
                            location.find((l) => l.id == values.locationId)?.BSB
                          }{' '}
                          <br />
                          Account Number:{' '}
                          {
                            location.find((l) => l.id == values.locationId)
                              ?.accountNumber
                          }{' '}
                          <br />
                        </p>
                      </>
                    )}
                  </div>
                  {/* {!wholeFormDisable && (
                  <> */}
                  {/* </>
                )} */}
                </div>
                <div className='w-full'>
                  {payload.length > 0 && (
                    <div className="flex justify-end space-x-10">
                      <div className="bg-gray-200 rounded-lg shadow border mt-4">
                        <div className="flex justify-between px-2 text-sm font-mono text-gray-700 mt-2 space-x-10"   >
                          <p>Cart Subtotal:</p>
                          <p className="pr-2">${totalAmount?.toFixed(2)}</p>
                        </div>
                        {type == 'edit' && (
                          <div className="flex justify-between px-2 text-sm font-mono text-gray-700 mt-4">
                            <p>Amount Paid:</p>
                            <p className="pr-2">${partPayment?.toFixed(2)}</p>
                          </div>
                        )}
                        {type == 'edit' && (
                          <div className="flex justify-between px-2 text-sm font-mono text-gray-700 mt-4">
                            <p>Credit Note:</p>
                            <p className="pr-2">${creditPayment?.toFixed(2)}</p>
                          </div>
                        )}
                        {/* <div className="flex justify-between items-center px-2 text-sm font-mono text-gray-700 pt-3">
                      <p>Charge:</p>
                      <CurrencyInput
                        decimalsLimit={2}
                        value={charges}
                        disabled={wholeFormDisable}
                        maxLength={totalAmount.toString().length}
                        className="block w-20 bg-gray-200 appearance-none rounded-md py-2 text-right px-2"
                        prefix="$"
                        onValueChange={(value) => {
                          calculateCharges(value)
                        }}
                      />
                    </div> */}
                        {!modal.data.familyInvoiceItem?.map(l => l.service).includes('Admin Charge') && (
                          <>
                            <div className="flex justify-between items-center px-2 text-sm font-mono text-gray-700 pt-3">
                              <p>Discount:</p>
                              <CurrencyInput
                                decimalsLimit={2}
                                value={discount}
                                disabled={wholeFormDisable}
                                maxLength={totalAmount.toString().length}
                                className="block w-20 bg-gray-200 appearance-none rounded-md py-2 text-right px-2"
                                prefix="$"
                                onValueChange={(value) => {
                                  calculateDiscount(value)
                                }}
                              />
                            </div>
                            {discountError && (
                              <>
                                <p className="text-sm text-red-600 text-right">
                                  Discount is more then Total Payable Amount
                                </p>
                              </>
                            )}
                            <div className="flex justify-between bg-gray-200 items-center px-2 text-sm font-mono text-gray-700 pt-3">
                              <p className="">Discount Description:</p>
                              <Select
                                className="text-left block w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                isSearchable
                                isDisabled={wholeFormDisable || discount == 0}
                                loadingMessage="Getting Discount Description..."
                                placeholder="Description"
                                value={
                                  descriptionValue
                                    ? {
                                      value: discountDescription?.find(
                                        (l) => l.id == descriptionValue
                                      )?.id,
                                      label: discountDescription?.find(
                                        (l) => l.id == descriptionValue
                                      )?.description,
                                    }
                                    : null
                                }
                                options={discountDescription?.map((l) => ({
                                  ...l,
                                  label: l.description,
                                  value: l.id,
                                }))}
                                onChange={(option) => {
                                  setDescriptionValue(option.value)
                                }}
                                onBlur={handleBlur}
                                menuPlacement="auto"
                                maxMenuHeight={110}
                              />
                            </div>
                          </>
                        )}
                        <hr class="h-px my-2 bg-black border-0 w-full px-2" />
                        <div className="flex justify-between px-2 text-sm font-mono text-gray-700 pt-1 mb-2">
                          <p>Total Amount Payable:</p>
                          <p
                            className={classNames(
                              !modal.data.familyInvoiceItem?.map(l => l.service).includes('Admin Charge')
                                ? 'ml-0'
                          : 'ml-20','pr-2'
                            )}
                          >${amountPayable?.toFixed(2)}</p>
                        </div>
                      </div>
                    </div>
                  )}
              {modal.data.source=='Teachwork' &&(
                    <div className="flex justify-end">
                      <div className="bg-gray-200 rounded-lg shadow border mt-4">
                        <div className="flex justify-between px-2 text-sm font-mono text-gray-700 mt-2">
                          <p>Cart Subtotal:</p>
                          <p className="pr-2">${modal.data.amountPayable?.toFixed(2)}</p>
                        </div>
                        <hr class="h-px my-2 mx-2 bg-black border-0" />
                        <div className="flex justify-between px-2 text-sm font-mono text-gray-700 pt-1 mb-2">
                          <p>Total Amount Payable:</p>
                          <p>${amountPayable?.toFixed(2)}</p>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className='flex justify-end items-center'>
                    {type == 'edit' && modal.data.source != 'Teachwork' && modal.data.xero_invoice_status != 'VOIDED'  && modal.data.xero_invoice_balance !=0 &&(
                      <>
                        <div className="mt-4 sm:mt-6 bottom-3 right-3">
                          <Button type="button"
                            className="bg-green-500 hover:bg-green-600 duration-500 cursor-pointer"
                          onClick={()=> {
                              onMarkAsPaid(modal.data.id, null)
                            }}
                            disabled={amountPayable == 0 || modal.data.source == 'Teachwork' || modal.data.xero_invoice_status != 'AWAITING PAYMENT'}>
                            Register a payment
                          </Button>
                        </div>
                      </>
                    )}
                    {type == 'edit' && modal.data.source != 'Teachwork' && modal.data.xero_invoice_status != 'VOIDED' && modal.data.paymentMaster?.length == 0 && (
                      <div className="mt-4 sm:mt-6 bottom-3 right-3">
                        <Button type="button"
                          className="bg-red-600 hover:bg-red-700 duration-500 cursor-pointer"
                          disabled={modal.data.paymentMaster?.length > 0}
                          onClick={()=> {
                            onDeleteOpen(modal.data.id, null)
                          }}>
                          Void
                        </Button>
                      </div>
                    )}
                    {type == 'edit' && modal.data.source == 'Teachwork' && modal.data.xero_invoice_balance!=0 && (
                      <div className="mt-4 sm:mt-6 bottom-3 right-3">
                        <Button type="button"
                        onClick={()=> {
                            markPaidTeachwork(modal.data.id, null)
                          }}
                          className="text-indigo-900 bg-indigo-100 hover:bg-indigo-400 duration-500 cursor-pointer">
                          <span className='text-indigo-900'>Mark as paid</span>
                        </Button>
                      </div>
                    )}
                    {/* {type == 'edit' && modal.data.source != 'Teachwork' && modal.data.xero_invoice_balance != modal.data.amountPayable && (
                         <div className="mt-4 sm:mt-6 bottom-3 right-3">
                          <Button type="button"
                          className="bg-yellow-600 hover:bg-yellow-700 duration-500 cursor-pointer" 
                          onClick={()=> {
                            markCreditNote(modal.data.id, null)
                          }}>
                            Allocate
                          </Button>
                        </div>
                    )} */}
                    {type == 'edit' && modal.data.source != 'Teachwork' && modal.data.xero_invoice_balance != modal.data.amountPayable && modal.data.xero_invoice_status != 'VOIDED' && (
                      <div className="mt-4 sm:mt-6 bottom-3 right-3">
                        <Button type="button"
                          className={classNames(
                            modal.data?.family?.creditNote?.some(e => e.balanceAmount != 0)
                              ? 'bg-gray-600'
                              : 'bg-yellow-600 hover:bg-yellow-700 duration-500',
                            'cursor-pointer'
                          )}
                          onClick={() => {
                            markCreditNote(modal.data.id, null)
                          }}
                          disabled={
                            modal.data?.family?.creditNote?.some(e => e.balanceAmount != 0) ? true : false
                          }
                        >
                          Issue credit note
                        </Button>
                      </div>
                    )}
                    {type == 'add' && amountPayable > 0 && (JSON.parse(localStorage.getItem('moldKey')).role == 'admin' ||
                      JSON.parse(localStorage.getItem('moldKey')).id == 300 || JSON.parse(localStorage.getItem('moldKey')).id == 251) &&
                      <div className="mt-4 sm:mt-6 bottom-3 right-3">
                        <Button
                          type="button"
                          className="bg-green-500 hover:bg-green-600 duration-500 cursor-pointer"
                          onClick={() => generateInvoicePdf()}
                        >
                          <PrinterIcon className='w-5 text-white mr-2 ' />
                          Preview
                        </Button>
                      </div>
                    }
                    <div className="mt-4 sm:mt-6 bottom-3 right-3">
                      {modal.data.source != 'Teachwork' && modal.data.xero_invoice_balance == modal.data.amountPayable && !modal.data.familyInvoiceItem?.map(l => l.service).includes('Admin Charge') && (
                        <Button
                          type="submit"
                          disabled={isSubmitting || modal.data.source === 'Teachwork' || (type === 'edit' && modal.data.amountPayable != modal.data.xero_invoice_balance)}
                        >
                          {isSubmitting
                            ? type === 'add'
                              ? 'Submitting...'
                              : 'Approving...'
                            : type === 'add'
                              ? 'Submit Invoice'
                              : 'Approve Invoice'}
                        </Button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {type == 'edit' && (
                  <>
                    <div className="">
                      <hr className="mt-4 w-full"></hr>
                      <div className="mt-4">
                      <div className='flex'>
                          <p>Payment History</p>
                          <d className='text-white text-md ml-2 bg-indigo-300 w-6 h-6 rounded-full flex justify-center'><p>{paymentHistory.paymentMaster.length}</p></d>
                        </div>
                        <div className="mt-1 w-full border">
                          <div className="w-full h-10 bg-gray-200 bg-stone-300 border-b">
                            <div class="grid grid-cols-4 gap-4">
                              <div className="flex justify-center mt-2 font-semibold">
                                Sr No
                              </div>
                              <div className="flex justify-center mt-2 font-semibold">
                                Amount
                              </div>
                              <div className="flex justify-center mt-2 font-semibold">
                                Date
                              </div>
                              <div className="flex justify-center mt-2 font-semibold">
                                Method
                              </div>
                            </div>
                          </div>
                          <div className='mb-10'> 
                          {paymentHistory.paymentMaster.map((answer, idx) => (
                            <div className="w-full h-10 bg-gray-100 shadow-sm">
                              <div class="grid grid-cols-4 gap-4">
                                <span className="cursor-pointer hover:text-indigo-800" onClick={()=>onStudentEditOpen(answer.id)}>
                                <div className="flex justify-center mt-2">
                                  {idx+1}
                                </div>
                                </span>
                                <div className="flex justify-center mt-2">
                                  ${answer.amount}
                                </div>
                                <div className="flex justify-center mt-2">
                                  {moment(answer.date).format('DD.MM.YYYY')}
                                </div>
                                <div className="flex justify-center mt-2">
                                  {answer.paymentMethod}
                                </div>
                              </div>
                            </div>
                          ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              {/* </div> */}
            </form>
          </BigSlideover>
        )}
      </Formik>
    )
  }

  const renderFamilyModal = () => {
    const { type, state, edit_id, data } = familyModal
    const {
      title,
      firstName,
      lastName,
      email,
      additionalEmail,
      mobileNo,
      workNo,
      homeNo,
      address1,
      address2,
      city,
      parentState,
      country,
      postCode,
      additionalInfo,
    } = data
    return (
      <Formik
        initialValues={data}
        validationSchema={familySchema}
        enableReinitialize
        onSubmit={(values, { setSubmitting, resetForm }) => {
          if (type === 'add') {
            app_api
              .post('/family-master', { ...values, active: parentEnabled,
              createdBy: JSON.parse(localStorage.getItem('moldKey')).email })
              .then((res) => {
                cleanFamilyModalData()
                getCRMInvoiceMasterData()
                toast.success('Successfully Added Family')
                setSubmitting(false)
                resetForm()
              })
              .catch((err) => {
                cleanFamilyModalData()
                toast.error(err.response.data.message)
                setSubmitting(false)
              })
          } else {
            app_api
              .patch(`/family-master/${edit_id}`, {
                ...values,
                active: parentEnabled,
                updatedBy: JSON.parse(localStorage.getItem('moldKey')).email
              })
              .then((res) => {
                cleanFamilyModalData()
                getCRMInvoiceMasterData()
                setSubmitting(false)
                toast.success('Successfully Updated Family')
                resetForm()
              })
              .catch((err) => {
                cleanFamilyModalData()
                toast.error(err.response.data.message)
              })
          }
        }}
      >
        {({
          handleBlur,
          Formik,
          handleChange,
          handleSubmit,
          setValues,
          setFieldTouched,
          values,
          touched,
          isValid,
          isSubmitting,
          errors,
        }) => (
          <BigSlideover
            open={state}
            setOpen={() => {
              setFamilyModal((prev) => ({ ...prev, state: false }))
            }}
            title={'Family' }
            handleClick={''}
            data={''}
          >
            <form onSubmit={handleSubmit} noValidate>
              <div className="mt-4 text-left">
                <div className="grid grid-cols-4 gap-4 flex flex-wrap w-full">
                  <div className="col-span-2 flex gap-2">
                    <div className="w-1/6">
                      <div className="flex mt-1">
                        <label className="block text-sm font-medium text-gray-700">
                          Title
                        </label>
                        {/* <span className='text-red-700 ml-1'>*</span> */}
                      </div>
                      <Select
                        className="text-left block w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                        name="title"
                        id="title"
                        isSearchable
                        loadingMessage="Getting Title..."
                        placeholder="Title..."
                        value={
                          values?.title
                            ? titles?.map((l) => ({
                              value: titles?.find((e) => e.id == values.title)
                                ?.id,
                              label: titles?.find((e) => e.id == values.title)
                                ?.name,
                            }))
                            : null
                        }
                        options={titles.map((l) => ({
                          ...l,
                          label: l.name,
                          value: l.id,
                        }))}
                        onChange={(option) => {
                          setValues({
                            ...values,
                            title: option.value,
                          })
                        }}
                        onBlur={handleBlur}
                        menuPlacement="auto"
                        maxMenuHeight={110}
                        onFocus={() => setFieldTouched('title', false)}
                      />
                      {touched.title && (
                        <p className="text-red-700 error_msg">{errors.title}</p>
                      )}
                    </div>
                    <div className="w-full">
                      <div className="flex">
                        <label className="block text-sm font-medium text-gray-700">
                          First Name
                        </label>
                        <span className="text-red-700 ml-1">*</span>
                      </div>
                      <input
                        name="firstName"
                        label="First Name"
                        value={values.firstName}
                        autoComplete="off"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        placeholder="Enter First Name..."
                        onFocus={() => setFieldTouched('firstName', false)}
                        className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      />
                      {touched.firstName && (
                        <p className="text-red-700 error_msg">
                          {errors.firstName}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="col-span-2">
                    <div className="flex">
                      <label className="block text-sm font-medium text-gray-700">
                        Last Name
                      </label>
                      <span className="text-red-700 ml-1">*</span>
                    </div>
                    <input
                      name="lastName"
                      label="Last Name"
                      value={values.lastName}
                      autoComplete="off"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="Enter Last Name..."
                      onFocus={() => setFieldTouched('lastName', false)}
                      className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    />
                    {touched.lastName && (
                      <p className="text-red-700 error_msg">
                        {errors.lastName}
                      </p>
                    )}
                  </div>
                </div>

                <div className="grid grid-cols-4 gap-4 flex flex-wrap mt-4">
                  <div className="col-span-2">
                    <div className="flex">
                      <label className="block text-sm font-medium text-gray-700">
                        Email Address
                      </label>
                      <span className="text-red-700 ml-1">*</span>
                    </div>
                    <input
                      name="email"
                      label="Email Address"
                      value={values.email}
                      autoComplete="off"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="Enter Email Address..."
                      onFocus={() => setFieldTouched('email', false)}
                      className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    />
                    {touched.email && (
                      <p className="text-red-700 error_msg">{errors.email}</p>
                    )}
                  </div>
                  <div className="col-span-2">
                    <div className="flex">
                      <label className="block text-sm font-medium text-gray-700">
                        Mobile No.
                      </label>
                      <span className="text-red-700 ml-1">*</span>
                    </div>
                    <Input
                      id="mobileNo"
                      name="mobileNo"
                      placeholder="Enter Mobile Number"
                      type="tel"
                      value={values.mobileNo}
                      autoComplete="off"
                      onBlur={handleBlur}
                      onChange={(e) => {
                        setValues({
                          ...values,
                          mobileNo: e.target.value,
                        })
                      }}
                      onFocus={() => setFieldTouched('mobileNo', false)}
                    />
                    {touched.mobileNo && (
                      <p className="text-red-700 mb-2 error_msg">
                        {errors.mobileNo}
                      </p>
                    )}
                  </div>
                  <div className="col-span-2">
                    <div className="flex">
                      <label className="block text-sm font-medium text-gray-700">
                        Address Line 1
                      </label>
                      {/*  <span className="text-red-500 ml-1">*</span> */}
                    </div>
                    <Input
                      name="address1"
                      // label="Address Line 1"
                      value={values.address1}
                      autoComplete="off"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="Enter Address Line 1..."
                    />
                    {touched.address1 && (
                      <p className="text-red-700 error_msg">
                        {errors.address1}
                      </p>
                    )}
                  </div>
                  <div className="col-span-2">
                    <div className="flex">
                      <label className="block text-sm font-medium text-gray-700">
                        City
                      </label>
                      {/*  <span className="text-red-500 ml-1">*</span> */}
                    </div>
                    <Select
                      className="text-left block w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      name="city"
                      id="city"
                      isSearchable
                      loadingMessage="Getting city..."
                      placeholder="Select city..."
                      value={
                        values.city
                          ? studentCity?.map((l) => ({
                            value: studentCity?.find(
                              (e) => e.name == values.city
                            )?.isoCode,
                            label: studentCity?.find(
                              (e) => e.name == values.city
                            )?.name,
                          }))
                          : null
                      }
                      options={studentCity?.map((l) => ({
                        ...l,
                        label: l.name,
                        value: l.name,
                      }))}
                      onChange={(option) => {
                        setValues({
                          ...values,
                          city: option.value,
                        })
                      }}
                      onFocus={() => setFieldTouched('city', false)}
                      onBlur={handleBlur}
                      menuPlacement="auto"
                      maxMenuHeight={110}
                    />
                    {touched.city && (
                      <p className="text-red-700 error_msg">{errors.city}</p>
                    )}
                  </div>
                  <div className="col-span-2">
                    <div className="flex">
                      <label className="block text-sm font-medium text-gray-700">
                        State
                      </label>
                    </div>
                    <Select
                      className="text-left block w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      name="parentState"
                      id="parentState"
                      isSearchable
                      loadingMessage="Getting state..."
                      placeholder="Select state..."
                      value={
                        values.parentState
                          ? studentState?.map((l) => ({
                            value: studentState?.find(
                              (e) => e.name == values.parentState
                            )?.isoCode,
                            label: studentState?.find(
                              (e) => e.name == values.parentState
                            )?.name,
                          }))
                          : defaultState
                            ? studentState?.map((l) => ({
                              value: studentState?.find(
                                (e) => e.name == defaultState[0].name
                              )?.isoCode,
                              label: studentState?.find(
                                (e) => e.name == defaultState[0].name
                              )?.name,
                            }))
                            : null
                      }
                      options={studentState?.map((l) => ({
                        ...l,
                        label: l.name,
                        value: l.name,
                      }))}
                      onChange={(option) => {
                        setValues({
                          ...values,
                          parentState: option.value,
                        })
                        getCities(option.countryCode, option.isoCode)
                        checkPostCode(values.postCode, option.value)
                      }}
                      onFocus={() => setFieldTouched('parentState', false)}
                      onBlur={handleBlur}
                      menuPlacement="auto"
                      maxMenuHeight={110}
                    />
                    {touched.parentState && (
                      <p className="text-red-700 error_msg">
                        {errors.parentState}
                      </p>
                    )}
                  </div>
                  <div className="col-span-2">
                    <div className="flex">
                      <label className="block text-sm font-medium text-gray-700">
                        Postcode
                      </label>
                      {/*  <span className="text-red-500 mx-1">*</span> */}
                    </div>
                    <input
                      name="postCode"
                      label="Postcode"
                      value={values.postCode}
                      autoComplete="off"
                      onBlur={handleBlur}
                      onChange={(e) => {
                        setValues({
                          ...values,
                          postCode: e.target.value,
                        })
                        checkPostCode(e.target.value, values.parentState)
                      }}
                      maxLength={4}
                      placeholder="Enter Postcode..."
                      onFocus={() => setFieldTouched('postCode', false)}
                      className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    />
                    {touched.postCode && (
                      <p className="text-red-700 error_msg">
                        {errors.postCode}
                      </p>
                    )}
                    {postCodeError && (
                      <p className="text-red-700 error_msg">{postCodeError}</p>
                    )}
                  </div>
                  <div className="col-span-2">
                    <div className="flex">
                      <label className="block text-sm font-medium text-gray-700">
                        Preferred location
                      </label>
                      <span className="text-red-700 ml-1">*</span>
                    </div>
                    <Select
                      className="text-left block w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      name="center"
                      id="center"
                      isSearchable
                      loadingMessage="Getting location..."
                      placeholder="Select a location..."
                      value={
                        values.center
                          ? location?.map((l) => ({
                            value: location?.find(
                              (e) => e.id == values.center
                            )?.id,
                            label: location?.find(
                              (e) => e.id == values.center
                            )?.name,
                          }))
                          : null
                      }
                      options={location.map((l) => ({
                        ...l,
                        label: l.name,
                        value: l.id,
                      }))}
                      onChange={(option) => {
                        setValues({
                          ...values,
                          center: option.value,
                        })
                      }}
                      onFocus={() => setFieldTouched('center', false)}
                      onBlur={handleBlur}
                      menuPlacement="auto"
                      maxMenuHeight={110}
                    />
                    {touched.center && (
                      <p className="text-red-700 error_msg">{errors.center}</p>
                    )}
                  </div>
                  <div className="mt-8">
                    <Switch.Group
                      as="div"
                      className="flex items-center justify-start"
                    >
                      <span className="flex flex-col">
                        <Switch.Label
                          as="span"
                          className="text-sm font-medium text-gray-900"
                          passive
                        >
                          Activate Parent
                        </Switch.Label>
                      </span>
                      <Switch
                        checked={parentEnabled == 'Active'}
                        onChange={() => {
                          handleParentCheckedChanges()
                        }}
                        className={classNames(
                          parentEnabled == 'Active'
                            ? 'bg-indigo-600'
                            : 'bg-gray-200',
                          'relative ml-4 inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
                        )}
                      >
                        <span
                          aria-hidden="true"
                          className={classNames(
                            parentEnabled == 'Active'
                              ? 'translate-x-5'
                              : 'translate-x-0',
                            'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                          )}
                        />
                      </Switch>
                    </Switch.Group>
                  </div>
                </div>
                {type == 'edit' && (
                  <>
                    <div className="">
                      <hr className="mt-4 w-full"></hr>
                      <div className="mt-4">
                        <div className='flex'>
                          <p>Students</p>
                          <d className='text-white text-md ml-2 bg-indigo-300 w-6 h-6 rounded-full flex justify-center'><p>{familyDetails.studentMaster.length}</p></d>
                        </div>
                        <div className="mt-1 w-full border">
                          <div className="w-full h-10 bg-gray-200 bg-stone-300 border-b">
                            <div class="grid grid-cols-3 gap-4">
                              <div className="flex justify-center mt-2 font-semibold">
                                Student Name
                              </div>
                              <div className="flex justify-center mt-2 font-semibold">
                                Grade
                              </div>
                              <div className="flex justify-center mt-2 font-semibold">
                                Status
                              </div>
                            </div>
                          </div>
                          {familyDetails.studentMaster.map((answer, idx) => (
                            <div className="w-full h-10 bg-gray-100 shadow-sm">
                              <div class="grid grid-cols-3 gap-4">
                                <div className="flex justify-center mt-2">
                                  <span className="cursor-pointer hover:text-indigo-300" onClick={() => onStudentEditOpen(answer.id)}>
                                    {answer.studentsFirstName +
                                      ' ' +
                                      answer.studentsLastName}
                                  </span>
                                </div>
                                <div className="flex justify-center mt-2">
                                  {answer.gradeMaster?.grade}
                                </div>
                                <div className="flex justify-center mt-2">
                                  {answer.active}
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                      <hr className="mt-4 w-full"></hr>
                      <div className="mt-4">
                        <div className='flex'>
                          <p>Invoice</p>
                          <d className='text-white text-md ml-2 bg-indigo-300 w-6 h-6 rounded-full flex justify-center'><p>{familyDetails.crmInvoice.length}</p></d>
                        </div>
                        <div className="mt-1 w-full border">
                          <div className="w-full h-10 bg-gray-200 bg-stone-300 border-b">
                            <div class="grid grid-cols-4 gap-4">
                              <div className="flex justify-center mt-2 font-semibold">
                                Invoice Number
                              </div>
                              <div className="flex justify-center mt-2 font-semibold">
                                Total
                              </div>
                              <div className="flex justify-center mt-2 font-semibold">
                                Balance
                              </div>
                              <div className="flex justify-center mt-2 font-semibold">
                                Status
                              </div>
                            </div>
                          </div>
                          {familyDetails.crmInvoice.map((answer, idx) => {
                            if (answer.xero_invoice_url != null) {
                              return (
                                <div className="w-full h-10 bg-gray-100 shadow-sm">
                                  <div class="grid grid-cols-4 gap-4">
                                    <div className="flex justify-center mt-2">
                                      <span className="cursor-pointer hover:text-indigo-300" onClick={() => onEditInvoiceOpen(answer.id)}>
                                        {answer.xero_invoice_no}
                                      </span>
                                    </div>
                                    <div className="flex justify-center mt-2">
                                      ${answer.amountPayable}
                                    </div>
                                    <div className="flex justify-center mt-2">
                                      ${answer.xero_invoice_balance}
                                    </div>
                                    <div className="flex justify-center mt-2">
                                      {answer.xero_invoice_status}
                                    </div>
                                  </div>
                                </div>
                              )
                            }
                          })}
                        </div>
                      </div>
                      <hr className="mt-4 w-full"></hr>
                      <div className="mt-4">
                        <div className='flex'>
                          <p>Payments</p>
                          <d className='text-white text-md ml-2 bg-indigo-300 w-6 h-6 rounded-full flex justify-center'><p>{familyDetails.crmInvoice.reduce((total, item) => total + item.paymentMaster.length, 0)}</p></d>
                        </div>
                        <div className="mt-1 w-full border">
                          <div className="w-full h-10 bg-gray-200 bg-stone-300 border-b">
                            <div class="grid grid-cols-6 gap-4">
                              <div className="flex justify-center mt-2 font-semibold">
                                ID
                              </div>
                              <div className="flex justify-center mt-2 font-semibold">
                                Invoice Number
                              </div>
                              <div className="flex justify-center mt-2 font-semibold">
                                Amount
                              </div>
                              <div className="flex justify-center mt-2 font-semibold">
                                Date
                              </div>
                              <div className="flex justify-center mt-2 font-semibold">
                                Payment Method
                              </div>
                              <div className="flex justify-center mt-2 font-semibold">
                                Description
                              </div>
                            </div>
                          </div>
                          {familyDetails.crmInvoice.map((answer, idx) => {
                            if (answer.xero_invoice_url != null) {
                              return (
                                <>
                                  {answer.paymentMaster.map((payment, idx) => {
                                    let numId = payment.id.toString()
                                    let locationName = payment.location.name
                                    let prefix
                                    while (numId.length < 6) {
                                      numId = '0' + numId
                                    }
                                    if (
                                      locationName ==
                                      'Ringwood (Head Office)' ||
                                      locationName == 'Ringwood' ||
                                      locationName == 'Online' ||
                                      locationName == 'Narre Warren' ||
                                      locationName == 'Officer' ||
                                      locationName == 'Beaconsfield'||
                                      locationName == 'Endeavour Hills'
                                    ) {
                                      prefix = 'RWPAY-'
                                    } else {
                                      prefix = 'CNPAY-'
                                    }
                                    return (
                                      <div class="grid grid-cols-6 gap-4">
                                        <div className="flex justify-center mt-2">
                                          {prefix}
                                          {numId}
                                        </div>
                                        <div className="flex justify-center mt-2">
                                          {answer.xero_invoice_no}
                                        </div>
                                        <div className="flex justify-center mt-2">
                                          ${payment.amount}
                                        </div>
                                        <div className="flex justify-center mt-2">
                                          {moment(payment.date).format(
                                            'DD-MM-YYYY'
                                          )}
                                        </div>
                                        <div className="flex justify-center mt-2">
                                          {payment.paymentMethod}
                                        </div>
                                        <div className="flex justify-center mt-2">
                                          {payment.description}
                                        </div>
                                      </div>
                                    )
                                  })}
                                </>
                              )
                            }
                          })}
                        </div>
                      </div>
                      <hr className="mt-4 w-full" />
                      <div className="mt-4">
                        <div className='flex'>
                          <p>Credit Notes</p>
                          <d className='text-white text-md ml-2 bg-indigo-300 w-6 h-6 rounded-full flex justify-center'><p>{familyDetails.creditNote.length}</p></d>
                        </div>
                        <div className="mt-1 w-full border">
                          <div className="w-full h-10 bg-gray-200 bg-stone-300 border-b">
                            <div class="grid grid-cols-5 gap-4">
                              <div className="flex justify-center mt-2 font-semibold">
                                Credit Number
                              </div>
                              <div className="flex justify-center mt-2 font-semibold">
                                Credit Amount
                              </div>
                              <div className="flex justify-center mt-2 font-semibold">
                                Credit Balance
                              </div>
                              <div className="flex justify-center mt-2 font-semibold">
                                Status
                              </div>
                              <div className="flex justify-center mt-2 font-semibold">
                                Description
                              </div>
                            </div>
                          </div>
                          {familyDetails.creditNote?.map((answer, idx) => {
                            if (answer.creditId != null) {
                              return (
                                <div className="w-full bg-gray-100 shadow-sm">
                                  <div class="grid grid-cols-5 gap-4">
                                    <div className="flex justify-center mt-2">
                                      <span className="cursor-pointer hover:text-indigo-300" onClick={() => onEditCreditOpen(answer.id)}>
                                        {answer.creditId}
                                      </span>
                                    </div>
                                    <div className="flex justify-center mt-2">
                                      ${answer.amount}
                                    </div>
                                    <div className="flex justify-center mt-2">
                                      ${answer.balanceAmount}
                                    </div>
                                    <div className="flex justify-center mt-2">
                                      {answer.status}
                                    </div>
                                    <div className="flex justify-center mt-2">
                                      {answer.description}
                                    </div>
                                  </div>
                                </div>
                              )
                            }
                          })}
                        </div>
                      </div>
                      <hr className="mt-4 w-full" />
                      <div className="mt-4">
                        <div className='flex'>
                          <p>Refunds</p>
                          <d className='text-white text-md ml-2 bg-indigo-300 w-6 h-6 rounded-full flex justify-center'><p>{familyDetails.creditNote.reduce((total, item) => total + item.refund.length, 0)}</p></d>
                        </div>
                        <div className="mt-1 w-full border">
                          <div className="w-full h-10 bg-gray-200 bg-stone-300 border-b">
                            <div class="grid grid-cols-4 gap-4">

                              <div className="flex justify-center mt-2 font-semibold">
                                Credit Number
                              </div>
                              <div className="flex justify-center mt-2 font-semibold">
                                Amount
                              </div>
                              <div className="flex justify-center mt-2 font-semibold">
                                Date
                              </div>
                              <div className="flex justify-center mt-2 font-semibold">
                                Payment Method
                              </div>
                            </div>
                          </div>
                          {familyDetails.creditNote.map((answer, idx) => {
                            if (answer.creditId != null) {
                              return (
                                <>
                                  {answer.refund.map((id, idx) => {
                                    return (
                                      <div className="w-full bg-white shadow-sm">
                                        <div class="grid grid-cols-4 gap-4">
                                          <div className="flex justify-center mt-2">
                                            {answer.creditId}
                                          </div>
                                          <div className="flex justify-center mt-2">
                                            ${id.amount}
                                          </div>
                                          <div className="flex justify-center mt-2">
                                            {moment(id.date).format('DD-MM-YYYY')}
                                          </div>
                                          <div className="flex justify-center mt-2">
                                            {id.status}
                                          </div>
                                        </div>
                                      </div>
                                    )
                                  })}
                                </>
                              )
                            }
                          })}
                        </div>
                      </div>
                    </div>
                  </>
                )}
                <div className="mt-5 sm:mt-6">
                  <Button type="submit" disabled={isSubmitting || postCodeError}>
                    {isSubmitting
                      ? type === 'add'
                        ? 'Adding...'
                        : 'Saving...'
                      : type === 'add'
                        ? 'Add Family'
                        : 'Update Family'}
                  </Button>
                </div>
              </div>
            </form>
          </BigSlideover>
        )}
      </Formik>
    )
  }

  const getGradeData = () => {
    app_api
      .get('/grade-master')
      .then((res) => {
        let data = res.data

        setError(null)
        setGrade(data)
      })
      .catch((err) => {

        setError(err?.response?.data?.message || 'error getting data')
      })
  }

  const renderStudent = () => {
    const { type, modalState, data, edit_id } = studentModal

    return (
      <Formik
        initialValues={data}
        validationSchema={
          familyStudentSchema
        }
        enableReinitialize
        onSubmit={(values, { setSubmitting, resetForm }) =>
        {
          app_api
            .patch(`/student-master/${edit_id}`, {
              studentsFirstName: values.firstName,
              studentsLastName: values.lastName,
              studentsEmail: values.email,
              grade: values.grade,
              center: values.center,
              studentPhone: values.mobileNumber,
              active: studentEnabled,
              updatedBy: JSON.parse(localStorage.getItem('moldKey')).email
            })
            .then((res) => {
              cleanStudentModalData()
              getCRMInvoiceMasterData()
              onFamilyEditOpen(res.data.familyId)
              if (res.data.active == 'Active') {
                toast.success('Student Activated Successfully')
              } else {
                toast.success('Student updated successfully')
              }

            })
            .catch((err) => {
              toast.error(err.response.data.message)
              cleanStudentModalData()
            })
        }
        }
      >
        {({
          handleBlur,
          Formik,
          handleChange,
          handleSubmit,
          setFieldTouched,
          setValues,
          values,
          touched,
          isValid,
          isSubmitting,
          errors,
        }) => (
          <BigSlideover
            open={modalState}
            setOpen={() => {
              setStudentModal((prev) => ({ ...prev, modalState: false }))
            }}
            title={'Student'}
            handleClick={''}
            data={''}
          >
            <form onSubmit={handleSubmit} noValidate>
              <div className="text-left">
                <div>
                  {/* Start Existing Parent */}

                  <>
                    <div className="mt-4">
                      <div className='flex'>
                        <label htmlFor="selectFamily" className="mb-1 text-sm text-gray-700 font-medium">
                          Select Family
                        </label>
                        <span className="text-red-700 ml-1">*</span>
                      </div>
                      {values.family}
                      <Select
                        className="text-left block w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                        name="selectFamily"
                        id="selectFamily"
                        isSearchable
                        loadingMessage="Getting Family Details..."
                        placeholder="Select Family..."
                        value={
                          values.selectFamily
                            ? parent?.map((l) => ({
                              value: parent?.find(
                                (e) => e.id == values.selectFamily
                              )?.id,
                              label:
                                parent?.find(
                                  (e) => e.id == values.selectFamily
                                )?.lastName +
                                ', ' +
                                parent?.find(
                                  (e) => e.id == values.selectFamily
                                )?.firstName,
                            }))
                            : null
                        }
                        options={parent.map((l) => ({
                          ...l,
                          label: l.lastName + ', ' + l.firstName,
                          value: l.id,
                        }))}
                        isDisabled={type == 'edit'}
                        onChange={(option) => {
                          setValues({
                            ...values,
                            selectFamily: option.value,
                          })
                        }}
                        onBlur={handleBlur}
                        menuPlacement="auto"
                        maxMenuHeight={110}
                        onFocus={() => setFieldTouched('selectFamily', false)}

                      />
                    </div>
                    {touched.selectFamily && (
                      <p className="text-red-700 error_msg">
                        {errors.selectFamily}
                      </p>
                    )}
                    <div className="mt-4"></div>
                    <div className="inline-grid grid-cols-2 gap-4 w-full">
                      <div>
                        <div className='flex'>
                          <label className="mb-1 text-sm text-gray-700 font-medium">Student First Name</label>
                          <span className="text-red-700 ml-1">*</span>
                        </div>
                        <Input
                          name="firstName"
                          autoComplete="off"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.firstName}
                          className="mb-2"
                          placeholder="Enter First Name"
                          onFocus={() => setFieldTouched('firstName', false)}
                        // onChange={(event) =>
                        //   onModalHandleChange(event.target.name, event.target.value)
                        // }
                        />
                        {touched.firstName && (
                          <p className="text-red-700 error_msg mb-2">
                            {errors.firstName}
                          </p>
                        )}
                      </div>
                      <div>
                        <div className='flex'>
                          <label className="mb-1 text-sm text-gray-700 font-medium">Student Last Name</label>
                          <span className="text-red-700 ml-1">*</span>
                        </div>
                        <Input
                          name="lastName"
                          autoComplete="off"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.lastName}
                          className="mb-2"
                          onFocus={() => setFieldTouched('lastName', false)}
                          placeholder="Enter Last Name"
                        // onChange={(event) =>
                        //   onModalHandleChange(event.target.name, event.target.value)
                        // }
                        />
                        {touched.lastName && (
                          <p className="text-red-700 error_msg mb-2">
                            {errors.lastName}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="mt-4"></div>
                    <div className="inline-grid grid-cols-2 gap-4 w-full">
                      <div>
                        <div className=""></div>
                        <label
                          htmlFor="contactId"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Student's Phone No.
                        </label>
                        <Input
                          name="mobileNumber"
                          // label="Student's Phone No."
                          onFocus={() => setFieldTouched('mobileNumber', false)}
                          value={values.mobileNumber}
                          placeholder="Enter student phone number"
                          // onChange={(event) =>
                          //   onModalHandleChange(event.target.name, event.target.value)
                          // }
                          autoComplete="off"
                          onBlur={handleBlur}
                          onChange={(e) => {
                            setValues({
                              ...values,
                              mobileNumber: e.target.value,
                            })
                          }}
                        />
                        {touched.mobileNumber && (
                          <p className="text-red-700 mb-2 error_msg">
                            {errors.mobileNumber}
                          </p>
                        )}
                      </div>
                      <div>
                        <div className="">
                          <label className="text-sm font-medium text-gray-700">
                            Select Grade
                          </label>
                          <span className="text-red-700 ml-1">*</span>
                        </div>
                        <Select
                          className="text-left w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                          name="grade"
                          id="grade"
                          isSearchable
                          loadingMessage="Getting grade..."
                          placeholder="Select a grade..."
                          value={
                            values.grade
                              ? grade?.map((l) => ({
                                value: grade?.find(
                                  (e) => e.id == values.grade
                                )?.id,
                                label: grade?.find(
                                  (e) => e.id == values.grade
                                )?.grade,
                              }))
                              : null
                          }
                          options={grade?.map((l) => ({
                            ...l,
                            label: l.grade,
                            value: l.id,
                          }))}
                          onChange={(option) => {
                            setValues({
                              ...values,
                              grade: option.value,
                            })
                          }}
                          onBlur={handleBlur}
                          menuPlacement="auto"
                          onFocus={() => setFieldTouched('grade', false)}
                          maxMenuHeight={110}
                        />
                        {touched.grade && (
                          <p className="text-red-700 error_msg mb-2 mt-2">
                            {errors.grade}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="mt-4 inline-grid grid-cols-4 gap-4 w-full">
                      <div className='col-span-2'>
                        <div className='flex'>
                          <label htmlFor="selectFamily" className="mb-1 text-sm text-gray-700 font-medium">
                            Email
                          </label>
                          <span className="text-red-700 ml-1"></span>
                        </div>
                        <Input
                          name="email"
                          autoComplete="off"
                          onChange={handleChange}
                          value={values.email}
                          onFocus={() => setFieldTouched('email', false)}
                          className="mb-2"
                          placeholder="Enter Email"
                        />

                        {touched.email && (
                          <p className="text-red-700 error_msg">
                            {errors.email}
                          </p>
                        )}

                      </div>
                      <div className="col-span-2">
                        <div className="flex">
                          <label className="block text-sm font-medium text-gray-700">
                            Location
                          </label>
                          <span className="text-red-700 ml-1">*</span>
                        </div>
                        <Select
                          className="text-left block w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                          name="center"
                          id="center"
                          isSearchable
                          loadingMessage="Getting location..."
                          placeholder="Select a location..."
                          isMulti={true}
                          value={
                            values.center
                              ? values?.center?.map((l) => ({
                                value: l,
                                label: location.find((e) => e.id == l)
                                  ?.name,
                              })) : null
                          }
                          options={location.map((l) => ({
                            ...l,
                            label: l.name,
                            value: l.id,
                          }))}
                          onChange={(option) => {
                            setValues({
                              ...values,
                              center: option.map((l) => l.value),
                            })
                          }}
                          isClearable={true}
                          onFocus={() => setFieldTouched('center', false)}
                          onBlur={handleBlur}
                          menuPlacement="auto"
                          maxMenuHeight={110}
                        />
                        {touched.center && (
                          <p className="text-red-700 error_msg">{errors.center}</p>
                        )}
                      </div>
                    </div>
                  </>
                  {/* End Existing Parent */}
                </div>
                <div className='mt-4'>
                  <Switch.Group
                    as="div"
                    className="flex items-center justify-start"
                  >
                    <span className="flex flex-col">
                      <Switch.Label
                        as="span"
                        className="text-sm font-medium text-gray-900"
                        passive
                      >
                        Activate Student
                      </Switch.Label>
                    </span>
                    <Switch
                      checked={studentEnabled == 'Active'}
                      onChange={() => { handleStudentCheckedChanges() }}
                      className={classNames(
                        studentEnabled == 'Active' ? 'bg-indigo-600' : 'bg-gray-200',
                        'relative ml-4 inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
                      )}
                    >
                      <span
                        aria-hidden="true"
                        className={classNames(
                          studentEnabled == 'Active' ? 'translate-x-5' : 'translate-x-0',
                          'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                        )}
                      />
                    </Switch>
                  </Switch.Group>
                </div>
                {type == 'edit' && (<>
                  <div className="mt-8 mb-4">
                    <div className='flex mb-4'>
                      <p className='font-bold text-xl'>Class</p>
                      <d className='text-white text-md ml-2 bg-indigo-300 w-6 h-6 rounded-full flex justify-center mt-1'><p>{data?.class?.length}</p></d>
                    </div>
                    <div className="mt-1 w-full border">
                      <div className="w-full h-10 bg-gray-200 bg-stone-300 border-b">
                        <div class="grid grid-cols-5 gap-4">
                          <div className="flex justify-center mt-2 font-semibold">
                            Class Name
                          </div>
                          <div className="flex justify-center mt-2 font-semibold">
                            Subject
                          </div>
                          <div className="flex justify-center mt-2 font-semibold">
                            Number of classes attended
                          </div>
                          <div className="flex justify-center mt-2 font-semibold">
                            Start Date
                          </div>
                          <div className="flex justify-center mt-2 font-semibold">
                            End Date
                          </div>
                        </div>
                      </div>
                      {data?.class?.map((c, idx) => (
                        <div className="w-full h-10 bg-gray-100 shadow-sm">
                          <div class="grid grid-cols-5 gap-4">
                            <span className="cursor-pointer hover:text-indigo-800" >
                              <div className="flex justify-center mt-2">
                                {c?.className?.className}
                              </div>
                            </span>
                            <div className="flex justify-center mt-2">
                              {c?.className?.subject.subject}
                            </div>
                            <div className="flex justify-center mt-2">
                              {data?.att?.filter(index => index.attendance == 1).length}
                            </div>
                            <div className="flex justify-center mt-2">
                              {moment(c?.className?.sessions[0]?.date).format('DD-MM-YYYY')}
                            </div>
                            <div className="flex justify-center mt-2">
                              {moment(c?.className?.sessions[c.className.sessions.length - 1]?.date).format('DD-MM-YYYY')}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="mt-8 mb-4">
                    <div className='flex mb-4'>
                      <p className='font-bold text-xl'>Attendance</p>
                      <d className='text-white text-md ml-2 bg-indigo-300 w-6 h-6 rounded-full flex justify-center mt-1'><p>{data.att.filter(index => index.attendance == 1).length}</p></d>
                    </div>
                    <div className="mt-1 w-full border">
                      <div className="w-full h-10 bg-gray-200 bg-stone-300 border-b">
                        <div class="grid grid-cols-5 gap-4">
                          <div className="flex justify-center mt-2 font-semibold">
                            Class Name
                          </div>
                          <div className="flex justify-center mt-2 font-semibold">
                            Subject
                          </div>
                          <div className="flex justify-center mt-2 font-semibold">
                            Date of attended class
                          </div>
                          <div className="flex justify-center mt-2 font-semibold">
                            Start Time
                          </div>
                          <div className="flex justify-center mt-2 font-semibold">
                            End Time
                          </div>
                        </div>
                      </div>
                      {data?.att?.filter(index => index.attendance == 1).map((c, idx) => (
                        <div className="w-full h-10 bg-gray-100 shadow-sm">
                          <div class="grid grid-cols-5 gap-4">
                            <span className="cursor-pointer hover:text-indigo-800" >
                              <div className="flex justify-center mt-2">
                                {c?.class?.className}
                              </div>
                            </span>
                            <div className="flex justify-center mt-2">
                              {c?.class?.subject.subject}
                            </div>
                            <div className="flex justify-center mt-2">
                              {moment(c.date).format('DD-MM-YYYY')}
                            </div>
                            <div className="flex justify-center mt-2">
                              {moment(c?.from_time).format('h:mm A')}
                            </div>
                            <div className="flex justify-center mt-2">
                              {moment(c?.end_time).format('h:mm A')}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </>)}
                <div className="flex justify-end gap-2">
                  <div className="mt-4 sm:mt-6">
                    <Button
                      type="button"
                      className={'bg-red-500 hover:bg-red-700'}
                      onClick={() => { { setStudentModal((prev) => ({ ...prev, modalState: false })) } }}
                    >
                      Cancel
                    </Button>
                  </div>
                  <div className="mt-4 sm:mt-6">
                    <Button
                      type="submit"
                      disabled={isSubmitting}
                    >
                      {isSubmitting
                        ? 'Submitting'
                        : 'Submit'}
                    </Button>
                  </div>
                </div>
              </div>
            </form>
          </BigSlideover>
        )}
      </Formik>
    )
  }

  const renderAllocateModal = () => {
    const { type, state, data } = allocateModal
    return (
      <Formik
        initialValues={data}
        validationSchema={null}
        enableReinitialize
        onSubmit={(values) => {
          if (select.length > 0) {
            app_api
              .patch(`crm-invoice/allocateCreditNote/${allocateModal.edit_id}`, select)
              .then((res) => {
                toast.success('Successfully issued credit note')
                cleanAllocateModalData()
                onFamilyEditOpen(allocateModal.data?.credit.familyId)
              })
              .catch((err) => {
                toast.error('Failed to issue credit note')
                cleanAllocateModalData()
              })
          } else {
            toast.error('Please select any one of the invoices')
            cleanAllocateModalData()
          }
        }}
      >
        {({
          handleBlur,
          Formik,
          handleChange,
          handleSubmit,
          setValues,
          values,
          touched,
          isValid,
          isSubmitting,
          errors,
        }) => (
          <HalfGeneralSlideover
            open={state}
            setOpen={() => {
              setAllocateModal((prev) => ({ ...prev, state: false }))
              cleanAllocateModalData()
            }}
            type={
              allocateModal.data?.credit?.status == 'Issue Refund'
                ? 'Issue Refund'
                : allocateModal.data?.credit?.status == 'PAID'
                  ? 'PAID'
                  : null
            }
            title={'Allocate / Refund Credit Note'}
            handleClick={''}
            data={''}
          >
            <form onSubmit={handleSubmit} noValidate>
              <div className="p-4">
                <div className="flex flex-col md:flex-row justify-between items-start gap-4">
                  {allocateModal.data?.credit?.status != 'PAID' && (
                    <>
                      <div className="w-full md:w-1/2 bg-stone-200 rounded-lg shadow-md text-left">
                        <div className="overflow-hidden border">
                          <p className="text-lg font-semibold my-2 mx-3">
                            {allocateModal.data?.credit?.creditId}
                          </p>
                          <hr className="mx-3 border-t border-gray-300" />
                          <div className="flex justify-between my-1 mx-3 mt-2">
                            <p className="text-gray-700 text-md">
                              Credit Amount:
                            </p>
                            <p className="text-gray-700 text-md my-1 mx-3">
                              ${calculateAmount?.toFixed(2)}
                            </p>
                          </div>
                          <div className="flex justify-between my-1 mx-3">
                            <p className="text-gray-700 text-md">
                              Balance Credit:
                            </p>
                            <p className="text-gray-700 text-md my-1 mx-3">
                              ${balanceAmount?.toFixed(2)}
                            </p>
                          </div>
                          <div className="flex justify-between my-1 mx-3">
                            <p className="text-gray-700 text-md">Created At:</p>
                            <p className="text-gray-700 text-md my-1 mx-3">
                              {moment(creditCreatedAt).format('DD MMM YYYY')}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="w-1/2 fixedHeight">
                        {allocateModal.data?.credit?.balanceAmount != 0 && (
                          <div className="overflow-auto">
                            {allocateModal.data.credit?.family?.crmInvoice
                              .filter(
                                (answer) =>
                                  answer.xero_invoice_balance != 0 && answer.xero_invoice_status != 'PAID' && answer.xero_invoice_status != 'VOIDED' && answer.xero_invoice_no != null
                              )
                              .map((answer) => (
                                <div
                                  key={answer.id}
                                  className="bg-stone-200 rounded-lg text-left mb-4 px-4 py-2"
                                >
                                  <div className="overflow-hidden">
                                    <div className="flex items-center">
                                      <input
                                        type="checkbox"
                                        onChange={(e) =>
                                          handleCheckboxChange(
                                            answer.id,
                                            e.target.checked
                                          )
                                        }
                                        disabled={answer.disable}
                                      />
                                      <p className="text-lg font-semibold ml-2">
                                        {answer.xero_invoice_no}
                                      </p>
                                    </div>
                                    <hr className="my-2 border-t border-gray-300" />
                                    <div className="flex justify-between my-2.5">
                                      <p className="text-gray-700 text-md">
                                        Total Amount:
                                      </p>
                                      <p className="text-gray-700 text-md">
                                        ${answer.amountPayable}
                                      </p>
                                    </div>
                                    <div className="flex justify-between my-2.5">
                                      <p className="text-gray-700 text-md">
                                        Balance Amount:
                                      </p>
                                      <p className="text-gray-700 text-md">
                                        ${answer.xero_invoice_balance}
                                      </p>
                                    </div>
                                    <div className="flex justify-between my-2.5">
                                      <p className="text-gray-700 text-md">
                                        Created At:
                                      </p>
                                      <p className="text-gray-700 text-md">
                                        {moment(answer.createdAt).format(
                                          'DD MMM YYYY'
                                        )}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              ))}
                          </div>
                        )}
                      </div>
                    </>
                  )}
                  {allocateModal.data?.credit?.status === 'PAID' && (
                    <>
                      <div className="w-full md:w-2/2 bg-gray-200 rounded-lg shadow-md text-left">
                        <div className="overflow-hidden border">
                          <p className="text-lg font-semibold my-2 mx-3">
                            {allocateModal.data?.credit?.creditId}
                          </p>
                          <hr className="mx-3 border-t border-gray-300" />
                          <div className="flex justify-between my-1 mx-3 mt-2">
                            <p className="text-gray-700 text-md">
                              Credit Amount:
                            </p>
                            <p className="text-gray-700 text-md my-1 mx-3">
                              ${calculateAmount?.toFixed(2)}
                            </p>
                          </div>
                          <div className="flex justify-between my-1 mx-3">
                            <p className="text-gray-700 text-md">
                              Balance Credit:
                            </p>
                            <p className="text-gray-700 text-md my-1 mx-3">
                              ${balanceAmount?.toFixed(2)}
                            </p>
                          </div>
                          <div className="flex justify-between my-1 mx-3">
                            <p className="text-gray-700 text-md">Created At:</p>
                            <p className="text-gray-700 text-md my-1 mx-3">
                              {moment(creditCreatedAt).format('DD MMM YYYY')}
                            </p>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>

                {allocateModal.data?.credit?.status === 'PAID' && (
                  <>
                    <div className="mt-8">
                      <span className="font-mono font-semibold ">
                        Credit Note Allocation History:
                      </span>
                      {data.allocatedData.map((refund) => (
                        <>
                          {refund.PaymentID && (
                            <>
                              <div className="mt-4 font-semibold bg-blue-300 w-fit px-2 py-1 rounded-md text-sm">
                                Date:{' '}
                                {moment(refund.Date).format('DD MMM YYYY')}
                              </div>
                              <div
                                key={refund.PaymentID}
                                className="bg-stone-200 rounded-lg text-left mb-4 px-4 py-2 mt-2"
                              >
                                <div className="overflow-hidden">
                                  <div className="flex items-center">
                                    <p className="text-lg font-semibold">
                                      Credit Refund
                                    </p>
                                  </div>
                                  <hr className="my-2 border-t border-gray-300" />
                                  <div className="flex justify-between my-2.5">
                                    <p className="text-gray-700 text-md">
                                      Amount:
                                    </p>
                                    <p className="text-gray-700 text-md">
                                      ${refund.Amount}
                                    </p>
                                  </div>
                                  <div className="flex justify-between my-2.5">
                                    <p className="text-gray-700 text-md">
                                      Payment Method:
                                    </p>
                                    <p className="text-gray-700 text-md">
                                      {allocateModal?.data?.credit?.paymentMethod}
                                    </p>
                                  </div>
                                  <div className="flex justify-between my-2.5">
                                    <p className="text-gray-700 text-md">
                                      Refund Date:
                                    </p>
                                    <p className="text-gray-700 text-md">
                                      {moment(allocateModal?.data?.credit?.refund_date).format('DD MMM YYYY')}
                                    </p>
                                  </div>
                                  {allocateModal?.data?.credit?.paymentMethod != 'Cash' && (<>
                                    <div className="flex justify-between my-2.5">
                                      <p className="text-gray-700 text-md">
                                        Reference Number:
                                      </p>
                                      <p className="text-gray-700 text-md">
                                        {allocateModal?.data?.credit?.reference_number}
                                      </p>
                                    </div>
                                  </>
                                  )}
                                  <div className="flex justify-between my-2.5">
                                    <p className="text-gray-700 text-md">
                                      Refrence:
                                    </p>
                                    <p className="text-gray-700 text-md">
                                      "{refund.Reference}"
                                    </p>
                                  </div>
                                  {/* <div className="flex justify-between my-2.5">
                                        <p className="text-gray-700 text-md">
                                          Refund Date:
                                        </p>
                                        <p className="text-gray-700 text-md">
                                        {moment(refund.Date).format('DD MMM YYYY')}
                                        </p>
                                      </div> */}
                                </div>
                              </div>
                              <hr></hr>
                            </>
                          )}
                          {refund.AllocationID && (
                            <>
                              <div className="mt-4 font-semibold bg-blue-300 w-fit px-2 py-1 rounded-md text-sm">
                                Date:{' '}
                                {moment(refund.Date).format('DD MMM YYYY')}
                              </div>
                              <div
                                key={refund.AllocationID}
                                className="bg-stone-200 rounded-lg text-left mb-4 px-4 py-2 mt-2"
                              >
                                <div className="overflow-hidden">
                                  <div className="flex items-center">
                                    <p className="text-lg font-semibold">
                                      Amount Allocated to Invoice:{' '}
                                      {refund.Invoice.InvoiceNumber}
                                    </p>
                                  </div>
                                  <hr className="my-2 border-t border-gray-300" />
                                  <div className="flex justify-between my-2.5">
                                    <p className="text-gray-700 text-md">
                                      Amount:
                                    </p>
                                    <p className="text-gray-700 text-md">
                                      ${refund.Amount}
                                    </p>
                                  </div>
                                  <div className="flex justify-between my-2.5">
                                    <p className="text-gray-700 text-md">
                                      Balance Amount:
                                    </p>
                                    <p className="text-gray-700 text-md">
                                      $
                                      {allocateModal.data.credit?.family?.crmInvoice
                                        ?.filter(
                                          (e) =>
                                            e.xero_invoice_no ==
                                            refund.Invoice.InvoiceNumber
                                        )
                                        .map(
                                          ({ xero_invoice_balance }) =>
                                            xero_invoice_balance
                                        )}
                                    </p>
                                  </div>
                                  {/* <div className="flex justify-between my-2.5">
                                  <p className="text-gray-700 text-md">
                                    Allocated Date:
                                  </p>
                                  <p className="text-gray-700 text-md">
                                  {moment(refund.Date).format('DD MMM YYYY')}
                                  </p>
                                </div> */}
                                </div>
                              </div>
                              <hr></hr>
                            </>
                          )}
                        </>
                      ))}
                    </div>
                  </>
                )}
              </div>
              {allocateModal.data?.credit?.status != 'PAID' &&
                allocateModal.data?.credit?.status != 'Issue Refund' && (
                  <div className="flex gap-4 bottom-4">
                    <div className="w-1/2">
                      <Button
                        type="submit"
                        disabled={
                          isSubmitting || allocateModal.data?.credit?.status == 'PAID' || allocateModal.data.credit?.family?.crmInvoice.filter(
                            (answer) =>
                              answer.xero_invoice_balance != 0 && answer.xero_invoice_status != 'PAID' && answer.xero_invoice_status != 'VOIDED' && answer.xero_invoice_no != null
                          )
                            .map((answer) => (<></>)).length == 0
                        }
                        loading={loading}
                      >
                        Allocate
                      </Button>
                    </div>
                    <div className="w-1/2">
                      <Button
                        type="button"
                        disabled={
                          allocateModal.data?.credit?.status == 'PAID' ||
                          select.length > 0
                        }
                        loading={loading}
                        className="bg-green-500 hover:bg-green-600 duration-500 cursor-pointer"
                        onClick={() => {
                          onRefundMark(allocateModal.edit_id)
                        }}
                      >
                        Refund
                      </Button>
                    </div>
                  </div>
                )}
            </form>
          </HalfGeneralSlideover>
        )}
      </Formik>
    )
  }

  const renderMarkAsPaidModalRefund = () => {
    const { type, refund_state, refund_edit_id, data } = refundModal
    return (
      <Formik
        initialValues={data}
        validationSchema={refundSchema}
        enableReinitialize
        onSubmit={(values, { setSubmitting, resetForm }) => {
          if (type === 'add') {
            app_api
              .post('/category', values)
              .then((res) => {
                cleanRefundModalData()
                onFamilyEditOpen(allocateModal.data?.credit.familyId)
                setSubmitting(false)
                resetForm()
              })
              .catch((err) => {
                if (err.response.status == 424) {
                  toast.error('Duplicate Entry')
                } else {
                  toast.error('Something Went Wrong')
                }
                cleanRefundModalData()
                setSubmitting(false)
              })
          } else {
            app_api
              .patch(`refund/refundCreditNote/${refundModal.refund_edit_id}`, {
                BSB: values.BSB,
                paymentMethod: values.paymentMethod,
                date: values.date,
                amount: values.credit.balanceAmount
              })
              .then((res) => {
                onFamilyEditOpen(res.data.credit.familyId)
                cleanRefundModalData()
                setSubmitting(false)
                resetForm()
                toast.success('Successfully Updated')
              })
              .catch((err) => {
                // toast.error('Not processed refund')
                // getCategoryData()
                cleanRefundModalData()
              })
          }
        }}
      >
        {({
          handleBlur,
          Formik,
          handleChange,
          handleSubmit,
          setValues,
          values,
          resetForm,
          touched,
          isValid,
          isSubmitting,
          errors,
          setFieldTouched,
        }) => (
          <Modal
            title="Refund"
            open={refund_state}
            type={allocateModal.data.status == 'PAID'}
            setOpen={() => {
              resetForm(true)
              setRefundModal((prev) => ({ ...prev, refund_state: false }))
              cleanRefundModalData()
            }}
          >
            <form onSubmit={handleSubmit} noValidate>
              <div className="text-left mt-2">
                <div className="flex justify-center items-center mb-2">
                  <p>{values?.credit?.creditId}</p>{allocateModal.data.status == 'PAID' && <><CheckCircleIcon className='w-5 text-green-600 ml-2' /><span className='text-green-600'>PAID</span></>}
                </div>
                <div className="flex">
                  <label className="block text-sm font-medium text-gray-700">
                    Refund Date
                  </label>
                  <span className="text-red-700 ml-1">*</span>
                </div>
                <div className="customDatePickerWidth">
                  <DatePicker
                    className="text-left h-9 block w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm border-gray-300 border-1 px-2"
                    name="date"
                    id="date"
                    dateFormat="dd-MM-yyyy"
                    selected={values.date ? moment(values.date).toDate() : new Date()}
                    onChange={(date) => {
                      setValues((prev) => ({
                        ...prev,
                        date: date,
                      }))
                    }}
                    disabled={values.status == 'PAID'}
                    placeholderText="Refund Date"
                    maxDate={new Date()}
                  />
                </div>
                {touched.date && (
                  <p className="text-red-700 error_msg">{errors.date}</p>
                )}
                <div className="grid lg:grid-cols-4 grid-cols-1 lg:gap-4 gap-1 flex flex-wrap">
                  <div className="col-span-4 mt-4">
                    <div className="flex">
                      <label className="block text-sm font-medium text-gray-700 mt-1">
                        Refund Amount
                      </label>
                    </div>
                    <CurrencyInput
                      id="amountPayable"
                      name="amountPayable"
                      disabled={true}
                      decimalsLimit={2}
                      value={values.credit?.balanceAmount}
                      placeholder="Enter amount"
                      className="text-left h-9 block w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm border-gray-300 border-1 px-2"
                      prefix="$"
                    />
                  </div>
                </div>
                <div className="flex mt-4">
                  <label className="block text-sm font-medium text-gray-700">
                    Method
                  </label>
                  <span className="text-red-700 ml-1">*</span>
                </div>
                <Select
                  className="text-left block w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  name="paymentMethod"
                  id="paymentMethod"
                  isSearchable
                  isDisabled={values.status == 'PAID'}
                  loadingMessage="Getting Payment Method..."
                  placeholder="Select a payment method..."
                  value={
                    values.paymentMethod
                      ? paymentType?.map((l) => ({
                        value: paymentType.find(
                          (e) => e.id == values.paymentMethod
                        )?.id,
                        label: paymentType.find(
                          (e) => e.id == values.paymentMethod
                        )?.name,
                      }))
                      : null
                  }
                  options={paymentType?.map((l) => ({
                    ...l,
                    label: l.name,
                    value: l.id,
                  }))}
                  onChange={(option) => {
                    setValues({
                      ...values,
                      paymentMethod: option.id,
                    })
                  }}
                  onBlur={handleBlur}
                  menuPlacement="auto"
                  maxMenuHeight={110}
                />
                {touched.paymentMethod && (
                  <p className="text-red-700 error_msg">
                    {errors.paymentMethod}
                  </p>
                )}
                {values.paymentMethod == 'Bank Transfer' && (
                  <>
                    <div className='mt-4'></div>
                    <div className='grid grid-cols-4 gap-4 flex flex-wrap mt-1'>
                      <div className='col-span-4'>
                        <div className='flex'>
                          <label className='block text-sm font-medium text-gray-700' >Transaction reference number</label>
                          {/* <span className='text-red-700 ml-1'>*</span> */}
                        </div>
                        <input
                          className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                          name="BSB"
                          label="BSB"
                          type="text"
                          value={values.BSB}
                          disabled={values.status == 'PAID'}
                          autoComplete="off"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          placeholder="Enter transaction reference number..."
                          onFocus={() => setFieldTouched('BSB', false)}
                        />
                        {touched.BSB && (
                          <p className="text-red-700 error_msg">{errors.BSB}</p>
                        )}
                      </div>
                    </div>
                  </>
                )}
                <div className="mt-4 sm:mt-6">
                  <Button
                    type="submit"
                    disabled={isSubmitting || allocateModal.data.status == 'PAID'}
                    loading={loading}
                  >
                    Refund
                  </Button>
                </div>
              </div>
            </form>
          </Modal>
        )}
      </Formik>
    )
  }

  const renderLocationModal = () => {
    const { type, state, edit_id, data } = locationModal
    return (
      <Formik
        initialValues={data}
        validationSchema={locationSchema}
        enableReinitialize
        onSubmit={(values, { setSubmitting, resetForm }) => {
          if (type === 'add') {
            app_api
              .post('/location', {
                ...values, gst: gstValue, abnStatus: abnStatus,
                entityName: entityName,
              })
              .then((res) => {
                getLocationData()
                setLocationModal((prev) => ({ ...prev, state: false }))
                setSubmitting(false)
                toast.success('Successfully added location')
                resetForm()
              })
              .catch((err) => {
                toast.error(err.response.data.message)
                getLocationData()
                // cleanModalData()
                setSubmitting(false)
              })
          } else {
            app_api
              .patch(`/location/${edit_id}`, { ...values, gst: gstValue })
              .then((res) => {
                let updatedLocationData = [...location]
                updatedLocationData[modal.index] = modal.data
                setLocation(updatedLocationData)
                getLocationData()
                toast.success('Successfully updated location')
                setLocationModal((prev) => ({ ...prev, state: false }))
                setSubmitting(false)
                resetForm()
              })
              .catch((err) => {
                toast.error(err.response.data.message)
              })
          }
        }}
      >
        {({
          handleBlur,
          Formik,
          handleChange,
          handleSubmit,
          setValues,
          setFieldTouched,
          values,
          touched,
          isValid,
          isSubmitting,
          errors,
          resetForm,
        }) => (
          <BigSlideover
            open={state}
            setOpen={() => {
              setLocationModal((prev) => ({ ...prev, state: false }))
            }}
            title={'Location'}
            handleClick={''}
            data={''}
          >
            <form onSubmit={handleSubmit} noValidate>
              <div className="text-left mt-4">
                <div className='grid grid-cols-4 gap-4 flex flex-wrap mt-1'>
                  <div className='col-span-2'>
                    <div className='flex'>
                      <label className='block text-sm font-medium text-gray-700' >Name</label>
                      <span className='text-red-700 ml-1'>*</span>
                    </div>
                    <input
                      name="name"
                      label="Name"
                      value={values.name}
                      autoComplete="off"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder='Enter Name...'
                      onFocus={() => setFieldTouched('name', false)}
                      className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    />
                    {touched.name && (
                      <p className="text-red-700 mb-1 error_msg">{errors.name}</p>
                    )}
                  </div>
                  <div className="col-span-2">
                    <div className='flex'>
                      <label className='block text-sm font-medium text-gray-700' >Point of Contact</label>
                      <span className='text-red-700 ml-1'>*</span>
                    </div>
                    <Select
                      className="text-left appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      name="pointOfContact"
                      id="pointOfContact"
                      isSearchable
                      loadingMessage="Getting User..."
                      placeholder="Select a user..."
                      value={
                        values.pointOfContact
                          ? LocationManager?.map((l) => ({
                            value: LocationManager?.find(
                              (e) => e.name == values.pointOfContact
                            )?.id,
                            label: LocationManager?.find(
                              (e) => e.name == values.pointOfContact
                            )?.name,
                          }))
                          : null
                      }
                      options={LocationManager.map((l) => ({
                        ...l,
                        label: l.name,
                        value: l.name,
                      }))}
                      onChange={(option) => {
                        setValues({
                          ...values,
                          pointOfContact: option.value,
                          userId: option.id
                        })
                      }}
                      onBlur={handleBlur}
                      menuPlacement="auto"
                      onFocus={() => setFieldTouched('city', false)}
                      maxMenuHeight={110}
                    />
                    {touched.city && (
                      <p className="text-red-700 error_msg mt-2">{errors.city}</p>
                    )}
                  </div>
                </div>
                <div className='mt-4'></div>
                <div className='grid grid-cols-4 gap-4 flex flex-wrap mt-1'>
                  <div className="col-span-2">
                    <div className='flex'>
                      <label className='block text-sm font-medium text-gray-700' >Phone / Mobile Number</label>
                      <span className='text-red-700 ml-1'>*</span>
                    </div>
                    <input
                      name="phoneNumber"
                      label="Mobile / Phone Number"
                      placeholder='Enter Mobile / Phone Number'
                      type="tel"
                      className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      value={values.phoneNumber}
                      autoComplete="off"
                      onFocus={() => setFieldTouched('phoneNumber', false)}
                      onBlur={(e) => {
                        setValues({
                          ...values,
                          phoneNumber: e.target.defaultValue,
                        })
                      }}
                      onChange={handleChange}
                    />
                    {touched.phoneNumber && (
                      <p className="text-red-700 mb-2 error_msg">
                        {errors.phoneNumber}
                      </p>
                    )}
                  </div>
                  <div className="col-span-2">
                    <div className='flex'>
                      <label className='block text-sm font-medium text-gray-700' >Email Address</label>
                      <span className='text-red-700 ml-1'>*</span>
                    </div>
                    <input
                      className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      name="emailAddress"
                      label="Email Address"
                      type="text"
                      value={values.emailAddress}
                      autoComplete="off"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      onFocus={() => setFieldTouched('emailAddress', false)}
                      placeholder="Enter Email Address..."
                    />
                    {touched.emailAddress && (
                      <p className="text-red-700 error_msg">{errors.emailAddress}</p>
                    )}
                  </div>
                </div>
                <div className='mt-4'></div>
                <div className='flex'>
                  <label className='block text-sm font-medium text-gray-700' >Address Line 1</label>
                  <span className='text-red-700 ml-1'>*</span>
                </div>
                <input
                  className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  name="address"
                  label="Address"
                  type="text"
                  value={values.address}
                  autoComplete="off"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  onFocus={() => setFieldTouched('address', false)}
                  placeholder="Enter Address..."
                />
                {touched.address && (
                  <p className="text-red-700 error_msg">{errors.address}</p>
                )}
                <div className='mt-4'></div>
                <div className='grid grid-cols-4 gap-4 flex flex-wrap mt-1'>
                  <div className='col-span-2'>
                    <div className='flex'>
                      <label className='block text-sm font-medium text-gray-700' >City</label>
                      <span className='text-red-700 ml-1'>*</span>
                    </div>
                    <Select
                      className="text-left appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      name="studentCity"
                      id="studentCity"
                      isSearchable
                      loadingMessage="Getting City..."
                      placeholder="Select City..."
                      value={
                        values.city
                          ? studentCity?.map((l) => ({
                            value: studentCity?.find(
                              (e) => e.name == values.city
                            )?.isoCode,
                            label: studentCity?.find(
                              (e) => e.name == values.city
                            )?.name,
                          }))
                          : null
                      }
                      options={studentCity.map((l) => ({
                        ...l,
                        label: l.name,
                        value: l.name,
                      }))}
                      onChange={(option) => {
                        setValues({
                          ...values,
                          city: option.value,
                          postCode: ''
                        })
                      }}
                      onBlur={handleBlur}
                      menuPlacement="auto"
                      maxMenuHeight={110}
                      onFocus={() => setFieldTouched('city', false)}
                    />
                    {touched.city && (
                      <p className="text-red-700 error_msg mt-2">{errors.city}</p>
                    )}
                  </div>
                  <div className='col-span-2'>
                    <div className='flex'>
                      <label className='block text-sm font-medium text-gray-700' >State</label>
                      <span className='text-red-700 ml-1'>*</span>
                    </div>
                    <Select
                      className="text-left block w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      name="state"
                      id="state"
                      isSearchable
                      loadingMessage="Getting State..."
                      placeholder="Select a State..."
                      value={
                        values.state
                          ? studentState?.map((l) => ({
                            value: studentState?.find((e) => e.name == values.state)?.isoCode,
                            label: studentState?.find((e) => e.name == values.state)?.name,
                          }))
                          : defaultState ?
                            studentState?.map((l) => ({
                              value: studentState?.find((e) => e.name == defaultState[0].name)?.isoCode,
                              label: studentState?.find((e) => e.name == defaultState[0].name)?.name
                            }))
                            : null
                      }
                      options={studentState.map((l) => ({
                        ...l,
                        label: l.name,
                        value: l.name,
                      }))}
                      onChange={(option) => {
                        getCities(
                          option.countryCode,
                          option.isoCode,
                        )
                        setValues({
                          ...values,
                          state: option.value,
                        })
                        checkPostCode(values.postCode, option.value)
                      }}
                      onBlur={handleBlur}
                      menuPlacement="auto"
                      maxMenuHeight={110}
                      onFocus={() => setFieldTouched('state', false)}
                    />
                    {touched.state && (
                      <p className="text-red-700 error_msg">{errors.state}</p>
                    )}
                  </div></div>
                <div className='mt-4'></div>

                <div className='flex'>
                  <label className='block text-sm font-medium text-gray-700' >Postcode</label>
                  <span className='text-red-700 ml-1'>*</span>
                </div>
                <input
                  className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  name="postCode"
                  label="postCode"
                  type="text"
                  value={values.postCode}
                  autoComplete="off"
                  onBlur={handleBlur}
                  onChange={(e) => {
                    setValues({
                      ...values,
                      postCode: e.target.value,
                    })
                    checkPostCode(e.target.value, values.state)
                  }}
                  placeholder="Enter postcode"
                  maxLength={4}
                  onFocus={() => setFieldTouched('postCode', false)}
                />
                {touched.postCode && (
                  <p className="text-red-700 error_msg">{errors.postCode}</p>
                )}
                {postCodeError && (
                  <p className="text-red-700 error_msg">{postCodeError}</p>
                )}
                <div className='mt-4'></div>
                <div className='flex'>
                  <label className='block text-sm font-medium text-gray-700' >Account Name</label>
                  <span className='text-red-700 ml-1'>*</span>
                </div>
                <input
                  className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  name="accountName"
                  label="Account Name"
                  type="text"
                  value={values.accountName}
                  autoComplete="off"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder="Enter Account Name..."
                  onFocus={() => setFieldTouched('accountName', false)}
                />
                {touched.accountName && (
                  <p className="text-red-700 error_msg">{errors.accountName}</p>
                )}
                <div className='mt-4'></div>
                <div className='grid grid-cols-4 gap-4 flex flex-wrap mt-1'>
                  <div className='col-span-2'>
                    <div className='flex'>
                      <label className='block text-sm font-medium text-gray-700' >BSB</label>
                      <span className='text-red-700 ml-1'>*</span>
                    </div>
                    <input
                      className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      name="BSB"
                      label="BSB"
                      type="text"
                      value={values.BSB}
                      autoComplete="off"
                      maxLength="6"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="Enter Bank BSB..."
                      onFocus={() => setFieldTouched('BSB', false)}
                    />
                    {touched.BSB && (
                      <p className="text-red-700 error_msg">{errors.BSB}</p>
                    )}
                  </div>
                  <div className='col-span-2'>
                    <div className='flex'>
                      <label className='block text-sm font-medium text-gray-700' >Account Number</label>
                      <span className='text-red-700 ml-1'>*</span>
                    </div>
                    <input
                      className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      name="accountNumber"
                      label="Account Number"
                      type="text"
                      value={values.accountNumber}
                      autoComplete="off"
                      maxLength="11"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="Enter Bank Account Number..."
                      onFocus={() => setFieldTouched('accountNumber', false)}
                    />
                    {touched.accountNumber && (
                      <p className="text-red-700 error_msg">{errors.accountNumber}</p>
                    )}
                  </div>
                </div>
                <div className='mt-4'></div>
                <div className='grid grid-cols-4 gap-4 flex flex-wrap mt-1'>
                  <div className='col-span-2'>
                    <div className='flex'>
                      <label className='block text-sm font-medium text-gray-700' >Google My Business</label>
                      {/*<span className='text-red-700 ml-1'>*</span>*/}
                    </div>
                    <input
                      className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      name="googleurl"
                      label="Account Number"
                      type="url"
                      value={values.googleurl}
                      autoComplete="off"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="https://www.google.com/"
                      onFocus={() => setFieldTouched('googleurl', false)}
                    />
                  </div>
                  <div className='col-span-2'>
                    <div className='flex'>
                      <label className='block text-sm font-medium text-gray-700' >Facebook</label>
                      {/*<span className='text-red-700 ml-1'>*</span>*/}
                    </div>
                    <input
                      className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      name="facebook"
                      label="Account Number"
                      type="url"
                      value={values.facebook}
                      autoComplete="off"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      onFocus={() => setFieldTouched('facebook', false)}
                      placeholder="https://www.facebook.com/"
                    />
                  </div>
                </div>
                <div className='mt-4'></div>
                <div className='flex'>
                  <label className='block text-sm font-medium text-gray-700' >ABN</label>
                  <span className='text-red-700 ml-1'>*</span>
                </div>
                <div className='flex'>
                  <input
                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    name="abn"
                    type="text"
                    value={values.abn}
                    autoComplete="off"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    onFocus={() => setFieldTouched('abn', false)}
                    placeholder="Enter Australian Business Number..."
                  />
                  <p className="w-8 rounded-r-md border-y border-r border-gray-300  py-1 appearance-none " onClick={() => getAbnValue(values.abn)}><MagnifyingGlassIcon className='w-full drop-shadow-md' /></p>
                </div>{touched.abn && (
                  <p className="text-red-700 error_msg">{errors.abn}</p>
                )}
                {abnError && (
                  <p className="text-red-700 error_msg">{abnError}</p>
                )}
                <div className='mt-4'></div>
                <div className='flex'>
                  <label className='block text-sm font-medium text-gray-700' >GST Register Date</label>
                </div>
                <input
                  className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  name="gst"
                  label="GST"
                  type="text"
                  value={values.gstValue}
                  autoComplete="off"
                  onBlur={handleBlur}
                  disabled
                  // onChange={handleChange}
                  placeholder={gstValue ? moment(gstValue).format('DD MM YYYY') : null}
                  onFocus={() => setFieldTouched('gst', false)}
                />
                {touched.gst && (
                  <p className="text-red-700 error_msg">{errors.gst}</p>
                )}
                <div className='mt-4'></div>
                <div className='grid grid-cols-4 gap-4 flex flex-wrap mt-1'>
                  <div className='col-span-2'>
                    <div className='flex'>
                      <label className='block text-sm font-medium text-gray-700' >ABN Status</label>
                    </div>
                    <input
                      className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      name="abnStatus"
                      type="text"
                      value={values.abnStatus}
                      autoComplete="off"
                      onBlur={handleBlur}
                      disabled
                      // onChange={handleChange}
                      placeholder={abnStatus}

                    />
                  </div><div className='col-span-2'>
                    <div className='flex'>
                      <label className='block text-sm font-medium text-gray-700' >Entity Name</label>
                    </div>
                    <input
                      className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      name="entityName"
                      type="text"
                      value={values.entityName}
                      autoComplete="off"
                      onBlur={handleBlur}
                      disabled
                      placeholder={entityName}
                    />
                  </div></div>
                <div className='mt-4'></div>
                <div className='flex'>
                  <label className='block text-sm font-medium text-gray-700' >Xero API Key</label>
                  {/* <span className='text-red-700 ml-1'>*</span> */}
                </div>
                <input
                  className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  name="xeroAPIKey"
                  label="Xero API Key"
                  type="text"
                  value={values.xeroAPIKey}
                  autoComplete="off"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder="Enter Xero API Key..."
                  onFocus={() => setFieldTouched('xeroAPIKey', false)}
                />
                {touched.xeroAPIKey && (
                  <p className="text-red-700 error_msg">{errors.xeroAPIKey}</p>
                )}
                <div className='mt-4'></div>
                <div className='flex'>
                  <label className='block text-sm font-medium text-gray-700' >Xero Invoice Branding Theme ID</label>
                  {/* <span className='text-red-700 ml-1'>*</span> */}
                </div>
                <input
                  className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  name="brandingThemeID"
                  type="text"
                  value={values.brandingThemeID}
                  autoComplete="off"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder="Enter Xero Invoice Branding Theme ID..."
                  onFocus={() => setFieldTouched('brandingThemeID', false)}
                />
                {touched.brandingThemeID && (
                  <p className="text-red-700 error_msg">{errors.brandingThemeID}</p>
                )}
                <div className="mt-4 sm:mt-6"></div>

                <div className="mt-4 sm:mt-6">
                  <Button type="submit"
                    disabled={isSubmitting || postCodeError}>
                    {isSubmitting ? (type === 'add' ? 'Adding...' : 'Saving...') : (type === 'add' ? 'Add Location' : 'Update Location')}
                  </Button>
                </div>
              </div>

            </form>
          </BigSlideover>
        )}
      </Formik>
    )
  }

  return (
    <Sidebar>
      <ConfirmationTextDialog
        setOpen={setConfirmationDialog}
        open={confirmationDialog}
        onDelete={onCancelInvoice}
        description="Do you really want to Void the invoice?"
        deactiveValue={modal.data.description ? modal.data.description : null}
        confirmationButtonText="Void"
        subheader="Please enter the reason to void"
        reason={`Student ${studentName} discontinued`}
        data={studentData}
      />
      <ConfirmDialog
        setOpen={setConfirmationRefundDialog}
        open={confirmationRefundDialog}
        onDelete={onRefundOpen}
        description=""
        confirmationButtonText="Refund"
      />
      {renderModal()}
      {renderMarkAsPaidModal()}
      {renderCreditModal()}
      {renderFamilyModal()}
      {renderStudent()}
      {renderAllocateModal()}
      {renderMarkAsPaidModalRefund()}
      {renderLocationModal()}
      <div className="px-4 pt-2 sm:px-6 lg:px-8 ContainerUI sticky min-h-screen">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-3xl font-semibold text-gray-900">Invoice</h1>
            <p className="my-2 text-sm text-gray-700">
              A list of all the invoices.
            </p>
          </div>
          <div className="sm:mt-0 sm:ml-16 sm:flex-none">
            <Button
              onClick={() => {
                getParentData()
                cleanModalData()
                setModal((prev) => ({ ...prev, type: 'add', state: true }))
              }}
              className="flex justify-center items-center"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-5 h-5 mt-0 mr-2"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 4.5v15m7.5-7.5h-15"
                />
              </svg>
              Create new invoice
            </Button>
          </div>
        </div>
        {loading ? (
          <div className="flex items-center justify-center">
            <div className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        ) :
          <>
            <div className="relative mx-2">
              <div className="w-full flex md:mt-10 mt-16 absolute justify-between gap-2">
                <div></div>
                <div className="flex justify-between gap-4 ">
                  <div
                    onClick={() => setIsFilterSidebarOpen(!isFilterSidebarOpen)}
                    className="rounded-full py-2 px-2 cursor-pointer border shadow-sm bg-indigo-800 hover:bg-indigo-700 text-white"
                  >
                    {filterData.loading ? (
                      <div className="flex items-center justify-center">
                        <div className="spinner-border animate-spin inline-block w-6 h-6 border-4 rounded-full" role="status">
                        </div>
                      </div>) : <CiFilter className="w-6 h-6" />}
                  </div>
                  <div
                    className="rounded-full py-2 px-2 cursor-pointer border shadow-sm bg-indigo-800 hover:bg-indigo-700 text-white"
                    onClick={() => {
                      downloadData();
                    }}
                  >
                    {downloadButtonLoading ? (
                      <div className="flex items-center justify-center">
                        <div className="spinner-border animate-spin inline-block w-6 h-6 border-4 rounded-full" role="status">
                        </div>
                      </div>) : <MdOutlineFileDownload className="w-6 h-6" />}
                  </div>
                </div>
              </div>

              {/* Filter sidebar */}
              {isFilterSidebarOpen && (
                <div className='h-full'>
                  <div className="absolute bg-white border rounded-lg shadow-lg w-full  md:w-1/2 lg:w-1/4 md:top-24 md:right-0 top-28 z-10">
                    <div className='flex justify-between p-2'>
                      <span className=" text-sm text-gray-700 ">Filters</span>
                      <span className='text-sm text-indigo-700 cursor-pointer' onClick={() => {
                        setFilterData({
                          startDate: null,
                          endDate: null,
                          status: '',
                          location: '',
                          loading: false
                        })
                        handleClearFilters()
                      }}> Reset All </span>
                    </div>
                    <hr className='' />
                    <div className="w-full flex flex-col p-2">
                      <div className='flex justify-between mb-2'>
                        <span className=" text-sm text-gray-700 ">Date Range</span>
                        <span className='text-sm text-indigo-700 cursor-pointer' onClick={() => {
                          setFilterData({
                            ...filterData,
                            startDate: null,
                            endDate: null,
                          })
                        }}> Reset </span>
                      </div>
                      <div className="w-full flex gap-2 justify-between">
                        <div className="flex customDatePickerWidth items-center">
                          <DatePicker
                            selected={null}
                            onChange={(dates) => {
                              const [start, end] = dates
                              // setStartDate(start)
                              // setEndDate(end)
                              setFilterData({ ...filterData, startDate: start, endDate: end })
                            }}
                            showIcon
                            isClearable
                            startDate={filterData.startDate}
                            endDate={filterData.endDate}
                            dateFormat="MMM dd, yyyy"
                            selectsRange
                            className="text-center block py-2 px-2 border w-full appearance-none rounded-md placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                            placeholderText="Select Date Range"
                            maxDate={new Date()}
                          />
                        </div>
                      </div>
                    </div>
                    <hr className='' />
                    <div className="w-full p-2">
                      <div className='flex justify-between mb-2'>
                        <span className=" text-sm text-gray-700 ">Location</span>
                        <span className='text-sm text-indigo-700 cursor-pointer' onClick={() => {
                          setFilterData({
                            ...filterData,
                            location: '',
                          })
                        }}> Reset </span>
                      </div>
                      <Select
                        className="text-left block w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                        name="location"
                        id="location"
                        isSearchable
                        loadingMessage="Getting Location..."
                        placeholder="Location"
                        isClearable={true}
                        isMulti
                        options={location?.map((l) => ({
                          ...l,
                          label: l.name,
                          value: l.id,
                        }))}
                        value={filterData.location}
                        onChange={(option) => {
                          setFilterData({
                            ...filterData,
                            location: option,
                          })
                          // setLocationFilter(option)
                        }}
                      />
                    </div>
                    <hr className='' />
                    <div className="w-full p-2">
                      <div className='flex justify-between mb-2'>
                        <span className=" text-sm text-gray-700 ">Status</span>
                        <span className='text-sm text-indigo-700 cursor-pointer' onClick={() => {
                          setFilterData({
                            ...filterData,
                            status: '',
                          })
                        }}> Reset </span>
                      </div>
                      <Select
                        className="text-left block w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                        name="invoiceStatus"
                        id="invoiceStatus"
                        placeholder="Filter By Status"
                        isClearable={true}
                        value={filterData.status}
                        options={invoiceStatusType?.map((l) => ({
                          ...l,
                          label: l.name,
                          value: l.id,
                        }))}
                        onChange={(option) => {
                          setFilterData({
                            ...filterData,
                            status: option,
                          })
                        }}
                      />
                    </div>
                    <hr className='' />
                    <div className='w-full p-2'>
                      <button className="block w-full text-left hover:bg-gray-100 flex gap-2 border rounded-md py-2 px-2 shadow-sm" onClick={() => setOpenSlider(true)}>
                        <PencilIcon className='w-4 text-indigo-800 hover:text-indigo-700 ' />
                        <span className='text-gray-500 text-sm'>Customize</span>
                      </button>
                    </div>
                    <hr className='' />
                    <div className="flex justify-between gap-2 p-2">
                      <div className="">
                        <Button
                          type="button"
                          className={'bg-red-500 hover:bg-red-700'}
                          onClick={() => {
                            setFilterData({
                              startDate: null,
                              endDate: null,
                              status: '',
                              location: '',
                              loading: false
                            })
                            handleClearFilters()
                          }}
                        >
                          Reset All
                        </Button>
                      </div>
                      <div className="">
                        <Button
                          type="button"
                          onClick={() => {
                            handleFilters()
                          }}
                        >
                          Apply Now
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            {renderTable()}
            {/* <Table
          progPage={page}
          totalCount={count}
          columns={crm_invoice_columns({
            onEditOpen,
            onDeleteOpen,
            onMarkAsPaid,
            markPaidTeachwork,
            onFamilyEditOpen,
            onResendMail,
          })}
          data={TabData}
          onEditClose={cleanModalData}
          setSearchFilter={setSearchFilter}
        /> */}
            <nav
              className="flex flex-col md:flex-row md:items-center md:justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6"
              aria-label="Pagination"
            >
              <div>
                <p className="text-sm text-gray-700">
                  Showing{' '}
                  <select
                    onChange={(e) => {
                      setLimit(e.target.value)
                      setPage(0)
                    }}
                    value={limit}
                  >
                    <option value={count}>{count}</option>
                    <option value="10">10</option>
                    <option value="15">15</option>
                    <option value="20">20</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    <option value="200">200</option>
                  </select>{' '}
                  of <span className="font-medium">{count}</span> results
                </p>
              </div>
              <div className="flex items-center md:justify-end">
                <span
                  onClick={goPrev}
                  className="relative mr-3 items-center rounded-md px-3 py-2 text-sm font-semibold text-gray-900 border hover:bg-indigo-800 hover:text-white duration-500 cursor-pointer"
                >
                  Previous
                </span>
                <span className="text-sm">
                  Page{' '}
                  <input
                    className="min-w-0 flex-1 rounded-md border border-gray-300 px-3 py-2 focus:border-indigo-700 focus:outline-none focus:ring-indigo-100 sm:text-sm"
                    type="number"
                    value={page + 1}
                    onChange={(e) => setPage(e.target.value - 1)}
                    max={totalPages}
                    min="1"
                  />
                  <span className='ml-2'></span>/ {Math.ceil(totalPages)}
                </span>
                <span
                  onClick={goNext}
                  className="relative ml-3 items-center rounded-md px-3 py-2 text-sm font-semibold text-gray-900 border hover:bg-indigo-800 hover:text-white duration-500 cursor-pointer"
                >
                  Next
                </span>
              </div>
            </nav>
          </>}
      </div>
      <ColumnSlideOver
        open={openSlider}
        setOpen={setOpenSlider}
        title={title}
        description={description}
        data={columnFilter}
        handleClick={handleClick}
      />
      <ToastContainer
        closeButton={false}
        closeOnClick={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover={false}
        autoClose={2000}
        hideProgressBar={false}/>
    </Sidebar>
  )
}

export default InvoiceMaster