import React, { useState, useEffect } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import logo from '../../assets/icons/logo.png'

export const AccessControl = ({ requiredRole, children }) => {
  const [user, setUser] = useState({})
  const navigate = useNavigate()

  const NavigateTo = () =>{
    const item = JSON.parse(localStorage.getItem('moldKey'))
    if(item){
      if(item.role === 'student'){
        navigate('/my-exams')
      }
      else{
        if(item.role === 'manager'){
          navigate('/dashboard')
          // navigate('/leads')
        }
      }
    }else{if(item.role === 'teacher'){
          navigate('/teacher-schedule')
    }else
    if(item.role === 'parent'){
      navigate('/teacher-schedule')
    }else{
      navigate('/dashboard')
      // navigate('/leads')
    }
    }
}

  useEffect(() => {
    try {
      setUser(JSON.parse(localStorage.getItem('moldKey')))
    } catch (err) {}
  }, [])

  if (requiredRole.includes(user.role)) {
    return children
  } else {
    return false
    // return (
    //   <div>
    //   <div className="md:flex  lg:flex-row justify-center">
    //     <div className="h-screen md:w-1/2 px-5 md:px-12 lg:px-48  flex flex-col  justify-center">
    //         <img src={logo} className="mb-16 w-48" />
    //       <span className="text-left" style={{ color: '#08285B' }}>
    //         Sorry
    //       </span>
    //       <h2 className="text-left text-6xl font-bold mb-4">
    //         Forbidden Page<br></br>
    //       </h2>
    //       {/* <span className="text-left" style={{ color: '#667085' }}>
    //         Sorry, the page you are looking for doesn't exist.<br/>
    //       </span> */}
    //       {/* <span className="text-left" style={{ color: '#667085' }}>
    //         Please enter your details.
    //       </span> */}
    //         <button
    //             className='mt-4 w-100 h-8'
    //             style={{
    //                 backgroundColor: '#3730A3',
    //                 color:'white',
    //                 borderRadius: '20px'
    //             }}
    //             type="button"
    //             onClick={()=>{
    //                 NavigateTo()
    //             }}
    //           >
    //             <span style={{display: 'flex', justifyContent:'center'}}>
    //                 <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
    //                     <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 15.75L3 12m0 0l3.75-3.75M3 12h18" />
    //                 </svg>&nbsp;&nbsp;&nbsp;
    //                 <p>
    //                     Go back to Main Page
    //                 </p>
    //             </span>
    //         </button>
    //       <div className="w-full">
    //       </div>
    //     </div>
    //   </div>
    // </div>
    // )
  }
}
