import React from 'react'
import { termMasterSchema } from '../../schema'
import { Formik } from 'formik'
import { ToastContainer, toast } from 'react-toastify'
import app_api from '../../config/api'
import Modal from '../../components/modals'
import Select from 'react-select'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import moment from 'moment'
import { Switch } from '@headlessui/react'
import Button from '../../components/button'
export default function AddTermModal(props) {
  const {
    type,
    state,
    data,
    edit_id,
    getBatchData,
    cleanModalData,
    setModal,
    termList,
    enabledSub,
    setEnabledSub,
    classNames,
    stateData,
    selectedState,
    setSelectedState,
    userData,
  } = props
  return (
    <Formik
      initialValues={data}
      validationSchema={termMasterSchema}
      enableReinitialize
      onSubmit={(values, { setSubmitting, resetForm }) => {
        if (userData.role == 'manager') {
          values.state = selectedState
        }
        if (type === 'add') {
          if(!values.state){
            setSubmitting(false)
            toast.error("Please select State")
            return;
          }
          if(!values.name){
            setSubmitting(false)
            toast.error("Please select Term")
            return;
          }
          if(!values.startDate){
            setSubmitting(false)
            toast.error("Please select Start Date")
            return;
          }
          if(!values.endDate){
            setSubmitting(false)
            toast.error("Please select End Date")
            return;
          }
          app_api
            .post('/terms-master/createTerm', {
              ...values,
              archive: enabledSub,
            })
            .then((res) => {
              toast.success('Successfully added Term Master')
              getBatchData()
              cleanModalData()
              setSubmitting(false)
              resetForm()
            })
            .catch((err) => {
              console.error('Error', err.response.statusCode)
              if (err.response.statusCode === 406) {
                toast.error('Something Went Wrong')
              } else {
                toast.error('Term Dates Already Exist')
              }
              cleanModalData()
              setSubmitting(false)
            })
        } else {
          app_api
            .patch(`/terms-master/updateTerm/${edit_id}`, {
              ...values,
              archive: enabledSub,
            })
            .then((res) => {
              setModal((prev) => ({
                ...prev,                
                state: false,
              }))
              getBatchData()
              toast.success('Successfully updated Terms Master')
              cleanModalData()
              setSubmitting(false)
              resetForm()
            })
            .catch((err) => {
              console.error(err)
              if (err.response.data.statusCode === 406) {
                toast.error(err.response.data.message)
              } else {
                toast.error('Something Went Wrong')
              }
              cleanModalData()
              setSubmitting(false)
            })
        }
      }}
    >
      {({
        handleBlur,
        handleChange,
        handleSubmit,
        setValues,
        setFieldTouched,
        values,
        touched,
        isValid,
        isSubmitting,
        errors,
      }) => (
        <Modal
          title={type === 'add' ? 'Submit' : 'Update'}
          open={state}
          setOpen={() => {
            setModal((prev) => ({ ...prev, state: false }))
          }}
        >
          <form onSubmit={handleSubmit} noValidate>
            {console.log('values?.startDate', values?.startDate)}
            <div className="text-left mt-4">
              <>
                <div className="flex">
                  <label className="block text-sm font-medium text-gray-700">
                    Name
                  </label>
                  <span className="text-red-700 ml-1">*</span>
                </div>
                <Select
                  className="text-left block w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  name="name"
                  id="name"
                  isSearchable
                  loadingMessage="Getting Term data..."
                  placeholder="Select a term"
                  value={
                    values?.name
                      ? termList?.map((l) => ({
                          value: termList.find((e) => e.id == values?.name)?.id,
                          label: termList.find((e) => e.id == values?.name)
                            ?.name,
                        }))
                      : null
                  }
                  options={termList?.map((l) => ({
                    ...l,
                    label: l.name,
                    value: l.id,
                  }))}
                  onChange={(option) => {
                    setValues({
                      ...values,
                      name: option?.id,
                    })
                  }}
                  onBlur={handleBlur}
                  menuPlacement="auto"
                  maxMenuHeight={110}
                  onFocus={() => setFieldTouched('name', false)}
                />
                {touched.name && (
                  <p className="text-red-700 error_msg mt-2">{errors.name}</p>
                )}
              </>

              <div className="mt-4"></div>
              <div className="flex w-[100%]">
                <div className="w-[50%]">
                  <div className="flex">
                    <label className="block text-sm font-medium text-gray-700">
                      Start Date
                    </label>
                    <span className="text-red-700 ml-1">*</span>
                  </div>
                  <DatePicker
                    className="text-center h-9 block w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm border-gray-300 border-1 px-2"
                    name="startDate"
                    showIcon
                    dateFormat="dd-MM-yyyy"
                    id="startDate"
                    selected={values.startDate ? moment(values.startDate).toDate() : moment().toDate()}
                    onChange={(date) => {
                      setValues({
                        ...values,
                        startDate: date,
                      })
                    }}
                    onFocus={() => setFieldTouched('startDate', false)}
                  />
                  {touched.startDate && (
                    <p className="text-red-700 error_msg mt-2">
                      {errors.startDate}
                    </p>
                  )}
                </div>
                <div className="w-[50%]">
                  <div className="flex">
                    <label className="block text-sm font-medium text-gray-700">
                      End Date
                    </label>
                    <span className="text-red-700 ml-1">*</span>
                  </div>
                  <DatePicker
                    className="text-center h-9 block w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm border-gray-300 border-1 px-2"
                    name="endDate"
                    showIcon
                    id="endDate"
                    dateFormat="dd-MM-yyyy"
                    selected={values.endDate ? moment(values.endDate).toDate() : moment().toDate() }
                    onChange={(date) => {
                      setValues({
                        ...values,
                        endDate: date,
                      })
                    }}
                    onFocus={() => setFieldTouched('endDate', false)}
                  />
                  {touched.endDate && (
                    <p className="text-red-700 error_msg mt-2">
                      {errors.endDate}
                    </p>
                  )}
                </div>
              </div>
              {userData.role == 'admin' && (
                <>
                  <div className="mt-4"></div>
                  <div className="flex">
                    <label className="block text-sm font-medium text-gray-700">
                      State
                    </label>
                    <span className="text-red-700 ml-1">*</span>
                  </div>
                  <Select
                    className="text-left block w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    name="state"
                    id="state"
                    isSearchable
                    loadingMessage="Getting State..."
                    placeholder="Select a State..."
                    value={selectedState || ""}
                    options={stateData}
                    onChange={(option) => {
                      setValues({
                        ...values,
                        state: [{ label: option?.value, value: option?.value }],
                      })
                      setSelectedState([
                        {
                          value: option?.value,
                          label: option?.value,
                        },
                      ])
                    }}
                    onBlur={handleBlur}
                    menuPlacement="auto"
                    maxMenuHeight={110}
                    onFocus={() => setFieldTouched('state', false)}
                  />
                  {touched.state && (
                    <p className="text-red-700 error_msg">{errors.state}</p>
                  )}
                </>
              )}

              <div className="mt-4">
                <Switch.Group
                  as="div"
                  className="flex items-center justify-between"
                >
                  <span className="flex flex-grow flex-col">
                    <Switch.Label
                      as="span"
                      className="text-sm font-medium text-gray-900"
                      passive
                    >
                      Term Date Status
                    </Switch.Label>
                  </span>
                  <Switch
                    checked={enabledSub}
                    onChange={setEnabledSub}
                    className={classNames(
                      enabledSub ? 'bg-indigo-600' : 'bg-gray-200',
                      'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 toggleButton'
                    )}
                  >
                    <span
                      aria-hidden="true"
                      className={classNames(
                        enabledSub ? 'translate-x-5' : 'translate-x-0',
                        'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                      )}
                    />
                  </Switch>
                </Switch.Group>
              </div>
              <div className="mt-5 sm:mt-6">
                <Button type="submit" loading={isSubmitting}>
                  {type === 'add' ? 'Submit' : 'Update'}
                </Button>
              </div>
            </div>
          </form>
        </Modal>
      )}
    </Formik>
  )
}
